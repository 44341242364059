<template>
    <div class="flex justify-between items-center flex-wrap">
        <p class="blue-text font-semibold text-4xl">
            $ {{ price }}
        </p>

        <Button
            text="Get It Now"
            color="blue"
            @click="$router.push({name: 'PurchaseIndex'})"
        />
    </div>
</template>

<script>
import Button from "@/components/ui/Button";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    name: "PurchasingBarActions",

    components: {
        Button
    },

    computed: {
        ...mapGetters({
            purchasingData: 'getPurchasingData',
        }),

        price() {
            let price = 0;
            let startData = this.purchasingData.startDate.value;
            let endData = this.purchasingData.endDate.value;
            let diffDays = 0;

            if (startData && endData) {
                startData = moment(startData);
                endData = moment(endData);
                diffDays = endData.diff(startData, 'days');
            }

            for (let i = 1; i <= diffDays; i++) {
                if (i <= 10) {
                    price += 10;
                } else if (i >= 11 && i <= 20) {
                    price += 7;
                } else if (i >= 21) {
                    price += 5;
                }
            }

            return price;
        }
    },
}
</script>

<style scoped>

</style>