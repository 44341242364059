<template>
    <div v-if="windowTop"
         class="main-bg w-12 h-12 fixed z-10 left-4 bottom-6 rounded-full flex items-center justify-center">
        <button
            @click="scrollToTop"
        >
            <img src="@/assets/svg/arrow-up.svg" alt="">
        </button>
    </div>
</template>

<script>
export default {
    name: "ScrollToTop",
    data() {
        return {
            windowTop: window.top.scrollY
        }
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll)
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll)
    },
    methods: {
        onScroll(e) {
            this.windowTop = window.top.scrollY
        },

        scrollToTop() {
            const myEl = document.getElementById('nav')

            this.$smoothScroll({
                scrollTo: myEl,
            })
        }
    }
}
</script>

<style lang="scss">

</style>