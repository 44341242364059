<template>
    <div class="purchase-rent-period flex flex-wrap justify-center py-4 w-full">
        <div
            v-if="this.selectedLang === 'en'"
            class="purchase-rent-period__days w-full mb-8"
        >
            <h1 class="text-center text-4xl sm:text-5xl font-semibold">
                WiFi Rental Period:
                <span class="text-5xl sm:text-6xl font-semibold orange-text">
                    {{ orderNumberOfDays.reduce((accumulator, currentValue) => accumulator + currentValue, 0) }}
                </span>
                days
            </h1>
        </div>
        <div
            v-if="this.selectedLang === 'jp'"
            class="purchase-rent-period__days w-full mb-8"
        >
            <h1 class="text-center text-5xl font-semibold">
                <span class="text-6xl font-semibold orange-text">
                    {{ orderNumberOfDays.reduce((accumulator, currentValue) => accumulator + currentValue, 0) }}
                </span>
                日間
            </h1>
        </div>

        <div class="purchase-rent-period__calendars w-full flex flex-wrap justify-center md:flex-nowrap">
            <div class="purchase-rent-period__calendar px-4 m-auto mb-4 md:mb-auto md:mr-8 md:w-full md:px-0">
                <v-date-picker :masks="masks" :min-date="minDate" v-model="startDate" :attributes="attrs">
                    <template v-slot="{ inputValue, inputEvents }">
                        <label class="text-black pl-2">{{ $t('dates.start_date') }}</label>
                        <input
                            class="w-full px-6 py-4 text-2xl border-sky-300 border-2 rounded-xl focus:outline-none focus:border-sky-300"
                            :value="inputValue"
                            v-on="inputEvents"
                            :placeholder="$t('order.select_start')"
                        />
                    </template>
                </v-date-picker>
            </div>

            <div class="purchase-rent-period__calendar px-4 m-auto md:w-full md:px-0">
                <v-date-picker :masks="masks" :min-date="minDate" v-model="endDate" :attributes="attrs">
                    <template v-slot="{ inputValue, inputEvents }">
                        <label class="text-black pl-2">{{ $t('dates.end_date') }}</label>
                        <input
                            class="w-full px-6 py-4 text-2xl border-sky-300 border-2 rounded-xl focus:outline-none focus:border-sky-300"
                            :value="inputValue"
                            v-on="inputEvents"
                            :placeholder="$t('order.select_end')"
                        />
                    </template>
                </v-date-picker>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import {useI18n} from "vue-i18n/dist/vue-i18n";

export default {
    name: "PurchaseRentPeriod",

    data() {
        return {
            startDate: null,
            endDate: null,
            minDate: new Date(Date.now()),
            masks: {
              input: 'MM/DD/YYYY',
            },
            attrs: [
                {
                    highlight: {
                        start: { fillMode: 'light' },
                        end: { fillMode: 'light' },
                    },
                    dates: {
                        start: new Date(), end: new Date()
                    }
                },
            ]
        }
    },

    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },

    computed: {
        ...mapGetters({
            orderData: 'getOrderData',
            orderNumberOfDays: 'getOrderNumberOfDays',
            orderTotalAmount: 'getOrderTotalAmountForClient',
            selectedLang: 'getSelectedLanguage'
        }),
    },

    watch: {
        startDate(value) {
            if (value) {
                this.$store.dispatch('setDestinationIndex', 0)
                let orderData = _.cloneDeep(this.orderData);
                orderData.from_date = value;
                this.attrs[0].dates.start = orderData.from_date
                this.$store.dispatch('setOrderData', orderData);
            }
        },
        endDate(value) {
            if (value) {
                this.$store.dispatch('setDestinationIndex', 0)
                let orderData = _.cloneDeep(this.orderData);
                orderData.to_date = value;
                this.attrs[0].dates.end = orderData.to_date
                this.$store.dispatch('setOrderData', orderData);
            }
        },
    },
    created() {
        this.setDefaultDates();
    },

    methods:{
        setDefaultDates() {
            let orderData = _.cloneDeep(this.orderData);

            if (orderData.from_date) {
                this.startDate = orderData.from_date;
            }

            if (orderData.to_date) {
                this.endDate = orderData.to_date;
            }
        }
    }
}
</script>

<style lang="scss">
    .purchase-rent-period {
        h1 {
            font-family: 'Montserrat', sans-serif;
        }
    }
</style>