import PurchaseRentIndex from "@/views/purchase/rent/Index";
import PurchaseCheckoutIndex from "@/views/purchase/checkout/Index";
import PurchaseCheckoutResult from "@/views/purchase/checkout/PurchaseCheckoutResult";
import GiftCard from "@/views/purchase/gift/GiftCard";
import GiftCheckoutResult from "@/views/purchase/gift/GiftCheckoutResult";

export default [
    {
        path: '/purchase-rent',
        name: 'PurchaseRentIndex',
        component: PurchaseRentIndex,
        meta: { title: 'Set Up Your Personal Plan to Rent Portable WiFi Hotspot | Skygo WiFi' },

    },
    {
        path: '/purchase-checkout',
        name: 'PurchaseCheckoutIndex',
        component: PurchaseCheckoutIndex,
        meta: {
            title: 'Customer Information | Skygo WiFi',
            metaTags: [
                {
                    name: 'robots',
                    content: 'noindex'
                },

            ]
        },
    },
    {
        path: '/purchase-checkout-result',
        name: 'PurchaseCheckoutResult',
        component: PurchaseCheckoutResult,
        meta: {
            title: 'Thank You For Purchasing Skygo WiFi | Skygo WiFi',
            metaTags: [
                {
                    name: 'robots',
                    content: 'noindex'
                },

            ]
        },

    },
    {
        path: '/gift-card',
        name: 'GiftCard',
        component: GiftCard,
        meta: {
            title: 'Gift Card | Skygo WiFi',
            metaTags: [
                {
                    name: 'robots',
                    content: 'noindex'
                },

            ]
        },

    },
    {
        path: '/gift-checkout-result',
        name: 'GiftCheckoutResult',
        component: GiftCheckoutResult,
        meta: {
            title: 'Thank You For Purchasing Gift Card | Skygo WiFi',
            metaTags: [
                {
                    name: 'robots',
                    content: 'noindex'
                },

            ]
        },

    },
]