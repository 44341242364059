<template>
    <div class="map">
        <iframe :src="map" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</template>
<script>
export default {
    name: 'App',
    props: {
        map: {
            type: String,
            default() {
                return 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d105915.20237755046!2d-118.401265!3d33.944984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b16f48709261%3A0x9dabe8de945b98a!2s1%20World%20Way%2C%20Los%20Angeles%2C%20CA%2090045%2C%20USA!5e0!3m2!1sen!2skg!4v1656929515680!5m2!1sen!2skg'
            }
        }
    }
}

</script>
<style lang="scss">
.map {
    background: #eee;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    iframe {
        width: 100%;
        height: 195px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        outline: none;
    }
}

</style>