<template>
  <div class="mt-40">
    <div class="container mx-auto">
      <div class="home-steps">
        <div>
          <hr class="w-12 mx-auto main-bg h-1 mb-4" />
          <h2 class="text-center mb-16 text-5xl blue-text">{{ $t('how_it_works.subtitle') }}</h2>
        </div>
        <div class="steps">
          <StepsCard
              class="cloud-blue rounded-lg py-12 px-3"
              :title="$t('how_it_works.steps.step_1.title')"
              :desc="$t('how_it_works.steps.step_1.desc')"
              :step="$t('how_it_works.steps.step_1.step')"
          >
            <template v-slot:image>
              <img  class="blue-cloud -top-2 w-[476px]" src="@/assets/png/cloud-left-blue.png" alt="">
            </template>
            <template v-slot:second-image>
              <img src="@/assets/png/skygo-purchase-cloud.png" alt="rental process">
            </template>
          </StepsCard>
          <StepsCard
            class="cloud-orange rounded-lg py-12 px-3"
            :title="$t('how_it_works.steps.step_2.title')"
            :desc="$t('how_it_works.steps.step_2.desc')"
            :step="$t('how_it_works.steps.step_2.step')"
          >
            <template v-slot:image>
              <img  class="blue-cloud -top-12 w-[582px]" src="@/assets/png/cloud-left-orange.png" alt="">
            </template>
            <template v-slot:second-image>
              <img src="@/assets/png/skygo-pocket.png" alt="portable wifi rental hotspot">
            </template>
          </StepsCard>
          <StepsCard
              class="cloud-green rounded-lg py-12 px-3"
              :step="$t('how_it_works.steps.step_3.step')"
              :desc="$t('how_it_works.steps.step_3.desc')"
              :title="$t('how_it_works.steps.step_3.title')"
          >
            <template v-slot:image>
              <img  class="blue-cloud -top-2 w-[435px]" src="@/assets/png/cloud-left-green.png" alt="">
            </template>
            <template v-slot:second-image>
              <img src="@/assets/png/get-img3.png" alt="business traveller using portable wifi hotspot">
            </template>
          </StepsCard>
          <StepsCard
              class="cloud-purple rounded-lg py-12 px-3"
              :step="$t('how_it_works.steps.step_4.step')"
              :title="$t('how_it_works.steps.step_4.title')"
              :desc="$t('how_it_works.steps.step_4.desc')"
          >
            <template v-slot:image>
              <img  class="blue-cloud -top-2 w-[435px]" src="@/assets/png/cloud-left-purple.png" alt="">
            </template>
            <template v-slot:second-image>
              <img src="@/assets/png/skygo-pocket-2.png" alt="mobile travel hotspot">
            </template>
          </StepsCard>
            <div class="font-medium flex items-center justify-center flex-wrap">
                <p class="">Have a question about the steps? Click the</p>
                <div class="flex items-center flex-wrap justify-center text-center">
                    <div class="bg-[#39bef9] px-2 mx-2 py-1 rounded-full rounded-tr-none flex items-center gap-1">
                        <img class="help-icon" src="https://assetscdn-wchat.freshchat.com/static/assets/help_icon.407f0467c911236190038c9631c321f8.svg" alt="">
                        <p class="text-[#3A4B67]">Help</p>
                    </div>
                    button right of the page and a customer service representative will answer any question you have.
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/ui/Button";
import StepsCard from "../../components/ui/StepsCard";
import {useI18n} from "vue-i18n/dist/vue-i18n";
export default {
  name: "Steps",
  components: {StepsCard, Button},
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    }
}
</script>

<style lang="scss">
.home-steps {
  h2 {
    font-weight: 700;
  }
  .blue-cloud {
    position: absolute;
    z-index: -1;
    left: calc(560px * (-0.4));
  }
}
</style>