<template>
    <div class="mb-6 w-full">
        <label v-if="label" :class="labelStyles()">{{ label }}</label>

        <v-select
            :class="inputStyles()"
            :label="showPropertyName"
            :reduce="option => option[selectPropertyName]"
            :options="options"
            :placeholder="placeholder"
            :disabled="disabled"
            v-model="modelValue"
            @search:blur="blur"
            @option:selected="selected"
            @input="input"
            @open="open"
            @close="close"
        />

        <p class="mt-2 text-sm text-red-600" v-if="errorMessage">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: "InputSelect",

    components: {
        vSelect
    },

    props: {
        size: {
            type: String,
            default() {
                return "base";
            },
        },
        errorMessage: {
            type: String,
            default() {
                return "";
            },
        },
        label: {
            type: String,
            default() {
                return "";
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
        modelValue: {
            default() {
                return null;
            },
        },
        placeholder: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default() {
                return [];
            },
        },
        selectPropertyName: {
            type: String,
            default() {
                return 'value';
            },
        },
        showPropertyName: {
            type: String,
            default() {
                return 'name';
            },
        }
    },

    data() {
        return {
            value: null,
        }
    },

    methods: {
        selected(option) {
            this.$emit('update:modelValue', option[this.selectPropertyName]);
            this.$emit('selected', option);
        },

        inputStyles() {
            let style = `input-select block w-full rounded-xl border
        focus:outline-offset-3 focus:outline-4 border-2 pl-4`;

            if (this.size === 'base') {
                style += ` text-base p-2`;
            }

            if (this.size === 'sm') {
                style += ` text-sm p-2`;
            }

            if (this.size === 'lg') {
                style += ` text-lg p-2`
            }

            if (this.errorMessage) {
                style += ` bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 outline-red-500`
            } else {
                style += ` bg-white border-sky-300 text-black placeholder-slate-200 outline-blue-300 focus:bg-white`
            }

            return style;
        },

        labelStyles() {
            let labelStyle = `block mb-2 text-sm `;
            if (this.errorMessage) {
                labelStyle += ` text-red-600 `;
            }
            return labelStyle;
        },

        blur() {
            this.$emit('blur');
        },

        input(val) {
            this.$emit('input', val);
        },

        open() {
            this.$emit('open');
        },

        close() {
            this.$emit('close');
        }
    },
}
</script>

<style lang="scss">
    .input-select .vs__dropdown-toggle {
        padding: 0;
        border: none;
    }

    .input-select .vs__selected-options {
        padding: 0;
      flex-wrap: nowrap;
    }

    .input-select .vs__selected {
        padding: 0;
        margin: 0;

    }

    .input-select .vs__search, .input-select .vs__search:focus {
        padding: 0;
        margin: 0;
        line-height: inherit;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #94a3b8;
            opacity: 1; /* Firefox */
        }
    }


    .input-select .vs__dropdown-menu {
        padding: 0;

    }

    .input-select .vs__actions {
        padding: 0;
    }
</style>