<template>
    <template v-if="!loading.component">
        <div class="flex justify-evenly xl:justify-evenly items-start flex-wrap gap-8 xl:gap-0 mb-8">
            <div class="max-w-xl w-full">
                <div class="flex justify-between flex-wrap gap-8 items-start ">
                    <div class="w-full">
                        <div class="flex justify-between items-end mb-8">
                            <p class="text-2xl font-semibold text-sky-300">
                                Enter your email address
                            </p>
                            <p class="text-[12px] sm:text-sm font-semibold text-sky-300">
                                You can send your Gift Card to someone after the purchase
                            </p>
                        </div>
                        <input-text
                            placeholder="Email address"
                            type="email"
                            class=""
                            v-model="data.email"
                        />
                    </div>
                    <div class="w-full">
                        <p class="text-2xl font-semibold mb-8 text-left text-sky-300">
                            Card information
                        </p>

                        <div id="card-element" class="mb-8"></div>
                    </div>
                </div>
                <div
                    class="flex justify-center md:justify-start"
                >
                    <button
                        v-if="!loading.checkout"
                        class='w-full justify-center lg:w-1/2 pointer text-center font-semibold text-white text-2xl uppercase flex items-center py-4 px-12 rounded-lg bg-lime-500'
                        @click="checkoutOrder"
                    >
                        Checkout
                    </button>

                    <button
                        v-if="loading.checkout"
                        class="pointer text-center font-semibold text-white text-2xl uppercase flex items-center py-5 px-20 rounded-lg bg-lime-500"
                        disabled
                    >
                        Loading...
                    </button>
                </div>
            </div>
            <div class="w-[611px]"></div>
        </div>
    </template>
</template>

<script>
import {loadStripe, StripePaymentElementOptions} from '@stripe/stripe-js';
import moment from "moment";
import {mapGetters} from "vuex";
import _ from "lodash";
import InputText from "@/components/ui/inputs/InputText";

export default {
    name: "GiftCheckoutPayment",
    components: {InputText},
    data() {
        return {
            stripe: null,
            elements: null,
            loading: {
                component: false,
                checkout: false,
            },
        }
    },
    computed: {
        ...mapGetters({
            paymentData: 'getPaymentData',
            user: 'getUser',
        }),
    },

    props: {
        data: {
            type: Object,
            default() {
                return null
            }
        },
        quantity: {
            type: Number,
            default() {
                return null
            }
        },
    },
    created() {
        if (this.user) {
            this.data.email = this.user.email
        }
    },
    mounted() {
        this.initStripe();
    },
    methods: {
        async initStripe() {
            let paymentData = _.cloneDeep(this.paymentData);
            paymentData.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
            paymentData.elements = paymentData.stripe.elements();
            paymentData.cardElement = paymentData.elements.create('card')
            paymentData.cardElement.mount('#card-element');
            this.$store.dispatch('setPaymentData', paymentData);
        },

        submit() {
            this.loading.checkout = true;

            this.paymentData.stripe.confirmPayment({
                elements: this.paymentData.elements,
                redirect: 'if_required',
                confirmParams: {
                    payment_method_data: {
                        billing_details: {
                            name: this.user.first_name,
                            email: this.data.email
                        }
                    }
                }
            })
                .then(result => {
                    if (result.error) {
                        this.loading.checkout = false;
                    } else if (result) {
                        this.checkoutOrder();
                    }
                })
                .catch(e => {
                    this.loading.checkout = false;
                });

        },
        generateRandom() {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let randomName = '';
            for (let i = 0; i < 6; i++) {
                randomName += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return randomName;
        },
        async checkoutOrder() {

            if (!this.user || !this.user.id) {
                this.$toast.info('You must be logged in to checkout.');
                return;
            }

            this.loading.checkout = true;
            let paymentIntent = null;
            try {
                paymentIntent = await this.$axios.get(`users/${this.user.id}/payment-intent`);
            } catch (e) {
                this.$toast.info(e.data.message);
                return;
            }

            const clientSecret = paymentIntent.data.client_secret;
            const {setupIntent, error} = await this.paymentData.stripe.confirmCardSetup(
                clientSecret, {
                    payment_method: {
                        card: this.paymentData.cardElement,
                    }
                }
            );
            if (error) {
                this.$toast.info(error.message);
                this.loading.checkout = false
                return;
            }

            let amount = this.data.amount
            const routeParams = {
                amount,
                quantity: this.quantity,
                code: this.generateRandom(),
                status: this.data.status,
                user_id: this.user.id,
                email: this.data.email,
                customer_id: this.user.id,
                payment_method_id: setupIntent.payment_method
            };
            this.$axios.post(`gift-cards/store`, routeParams)
                .then(res => {
                    this.$router.push({
                        name: 'GiftCheckoutResult',
                        params: routeParams
                    });
                    this.loading.checkout = false;
                }).catch(e => {
                this.loading.checkout = false;
                this.$toast.info(e.data.message);
            });
        }
    }
}
</script>

<style lang="scss">
#card-element {
    background: #ffffff;
    border-color: #7dd3fc;
    border-width: 2px;
    border-radius: 0.75rem;
    padding: .8rem 1rem;
}
</style>