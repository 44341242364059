<template>
<div class="card-container flex justify-between flex-col items-center gap-6  blue-border rounded-lg px-4 sm:px-4 py-12 sm:py-12 w-40 sm:w-48">
  <slot></slot>
  <p class="text-[10px] sm:text-sm blue-text text-center">{{ text }}</p>
</div>
</template>

<script>
export default {
  name: "Card",
  props: {
    text: {
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
.card-container {
  p {
    max-width: 100px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
}

</style>