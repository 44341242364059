<template>
    <div class="flex items-center justify-center">
<!--        <div class="payment-bar rounded-2xl flex items-center flex-wrap py-2 px-6 mx-2 lg:mx-0 bg-white relative">-->
<!--            <div class="payment-bar__destination w-full lg:w-44">-->
<!--                <purchasing-bar-destination />-->
<!--            </div>-->

<!--            <hr />-->

<!--            <div class="payment-bar__start-date w-32 py-2">-->
<!--                <purchasing-bar-start-date />-->
<!--            </div>-->

<!--            <hr />-->

<!--            <div class="payment-bar__end-date w-32 py-2">-->
<!--                <purchasing-bar-end-date />-->
<!--            </div>-->

<!--            <hr/>-->

<!--            <div class="payment-bar__actions w-full lg:w-80 border-t-2 lg:border-t-0 border-sky-300 py-2">-->
<!--                <purchasing-bar-actions />-->
<!--            </div>-->

<!--        </div>-->

        <button
            class="pointer text-center font-semibold text-white text-2xl uppercase flex items-center py-5 px-12 rounded-full bg-sky-300"
            @click="$router.push({name: 'PurchaseRentIndex'})"
        >
            {{$t('buttons.rent')}}
        </button>
    </div>
</template>

<script>
import Button from "@/components/ui/Button";
import PurchasingBarDestination from "@/views/purchase/_modules/purchasing-bar/PurchasingBarDestination";
import PurchasingBarStartDate from "@/views/purchase/_modules/purchasing-bar/PurchasingBarStartDate";
import PurchasingBarEndDate from "@/views/purchase/_modules/purchasing-bar/PurchasingBarEndDate";
import PurchasingBarActions from "@/views/purchase/_modules/purchasing-bar/PurchasingBarActions";

export default {
    name: "PurchasingBar",

    components: {
        PurchasingBarActions,
        PurchasingBarEndDate,
        PurchasingBarStartDate,
        PurchasingBarDestination,
        Button
    },
}
</script>

<style lang="scss">
.payment-bar {
    border: 3px solid #35b8f5;
    justify-content: space-between;
    hr {
        width: 2px;
        height: 35px;
        margin: auto 0;
        background-color: #35b8f5;
    }
}
</style>