<template>
    <app-layout>
        <div class="container mx-auto px-2">
            <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4 mt-16">Skygo Gift Card</h1>
            <div class="flex justify-evenly xl:justify-evenly items-start flex-wrap gap-8 xl:gap-0 mb-8">
                <div class="max-w-xl">
                    <img class="mb-8 shadow" src="@/assets/png/gift.jpg" alt="">
<!--                    <div class="flex justify-between flex-wrap gap-4 xl:gap-0">-->
<!--                        <p-->
<!--                            v-for="(value, index) in values"-->
<!--                            :key="index"-->
<!--                            :class="{-->
<!--          'py-2 text-white font-bold px-8 main-bg rounded-full cursor-pointer': selected === index,-->
<!--          'py-2 text-white font-bold px-8 bg-sky-300 rounded-full cursor-pointer hover:bg-[#ee6c31] transition': selected !== index-->
<!--        }"-->
<!--                            @click="selectItem(index)"-->
<!--                        >-->
<!--                            ${{ value }}-->
<!--                        </p>-->
<!--                    </div>-->
                    <p class="text-2xl font-semibold text-sky-300 mb-8">
                        Enter the amount of the gift card
                    </p>
                    <input-text
                        type="number"
                        placeholder="Amount"
                        v-model="form.amount"
                        @input="handleInput"
                    />
                </div>
                <div>
                    <div
                        class="border-2 border-sky-300 p-6 rounded-xl bg-white sm:mx-0"
                    >
                        <div class="flex mb-2 flex-wrap xl:flex-nowrap">
                            <div class="">
                                <img
                                    class="shadow max-w-[300px] mb-2 mr-0 xl:mr-6"
                                    src="@/assets/png/gift.jpg"
                                    alt="the best mobile hotspot for travel"
                                >
                            </div>

                            <div class="w-full">
                                <h1 class="font-semibold">
                                    Skygo Digital Gift Card
                                </h1>
                                <p>Valid for Skygo WiFi & Skygo Sim</p>
                            </div>
                        </div>
                        <hr class="w-full blue-bg h-0.5 mb-2"/>
                        <ul>
                            <li class="flex justify-between items-center mb-2">
                                <div class="flex gap-4 items-center">
                                    <p class="text-lg">Select Quantity</p>
                                    <div class="flex gap-4">
                                        <div class="w-10 h-10 border-2 border-sky-300 rounded-full flex items-center justify-center">
                                            <button
                                                @click="plus"
                                                class="text-4xl flex mb-2 text-sky-300"
                                            >
                                                +
                                            </button>
                                        </div>
                                        <div class="w-10 h-10 border-2 border-sky-300 rounded-full flex items-center justify-center">
                                            <button
                                                @click="minus"
                                                class="text-4xl flex mb-2 text-sky-300"
                                            >
                                                -
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <span>{{ quantity }}</span>
                            </li>
                            <li class="flex justify-between items-center mb-2">
                                <p class="text-lg">Amount</p>
                                <span>{{ form.amount }}</span>
                            </li>
                        </ul>

                        <hr class="w-full blue-bg h-0.5 mb-2"/>
                        <div class="flex justify-between items-center">
                            <p class="text-sky-300 text-3xl font-bold">{{ $t('order.total') }}</p>
                            <span class="text-sky-300 text-3xl font-bold">{{ form.amount * quantity }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <GiftCheckoutPayment
                :data="this.form"
                :quantity="this.quantity"
            />
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import GiftCheckoutPayment from "@/views/purchase/gift/GiftCheckoutPayment";
import {mapGetters} from "vuex";
import InputText from "@/components/ui/inputs/InputText";

export default {
    name: "GiftCard",
    components: {InputText, GiftCheckoutPayment, AppLayout},
    computed: {
        ...mapGetters({
            user: 'getUser',
        }),
    },

    data() {
        return {
            // values: [10, 25, 50, 100],
            // selected: null,
            quantity: 1,
            form: {
                code: '',
                amount: 1,
                status: 1,
                user_id: null,
                email: '',
            },
        };
    },
    created() {
        // this.selectItem(0)
    },
    watch: {
        // selectedValueData(newVal) {
        //     this.form.amount = newVal
        // },
        quantity(newVal) {
            this.quantity = newVal
        }
    },
    methods: {
        // selectItem(index) {
        //     this.selectedValueData = this.values[index]
        //     this.selected = index;
        // },
        handleInput: function() {
            // Проверяем, если значение меньше 1, устанавливаем его в 1
            if (this.form.amount < 1) {
                this.form.amount = 1;
            }
        },
        minus() {
            if(this.quantity > 1) {
                this.quantity--
            }
        },
        plus() {
            this.quantity++
        }
    }
}
</script>

<style scoped>

</style>