<template>
    <app-layout>
        <div>
            <div class="container mx-auto px-4">
                <purchase-rent-period/>
                <purchase-rent-country/>
                <purchase-rent-packages/>
                <div
                    class="flex mb-16 md:mb-0 flex-wrap gap-12 justify-start md:px-0 px-4 md:flex-nowrap md:justify-between"
                >
                    <div>
                        <purchase-rent-add-product />
                        <purchase-rent-pickup />
                        <purchase-rent-coupon />
                    </div>

                    <div>
                        <purchase-checkout-product-info />
                        <div class="flex justify-start md:justify-end mt-12">
                          <button
                              v-if="!loading"
                              class="pointer text-center font-semibold text-white text-2xl uppercase flex items-center py-5 px-20 rounded-lg bg-lime-500"
                              @click="routeToCheckoutIndex"
                          >
                              {{ $t('buttons.purchase') }}
                          </button>

                          <button
                              v-if="loading"
                              class="pointer text-center font-semibold text-white text-2xl uppercase flex items-center py-5 px-20 rounded-lg bg-lime-500"
                              disabled
                          >
                            Loading...
                          </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "../../../layouts/AppLayout";
import PurchaseRentPeriod from "@/views/purchase/rent/PurchaseRentPeriod";
import PurchaseRentCountry from "@/views/purchase/rent/PurchaseRentCountry";
import PurchaseRentPickup from "@/views/purchase/rent/PurchaseRentPickup";
import {mapGetters} from "vuex";
import PurchaseRentCoupon from "@/views/purchase/rent/PurchaseRentCoupon";
import PurchaseRentPackages from "./PurchaseRentPackages";
import PurchaseCheckoutProductInfo from "../checkout/PurchaseCheckoutProductInfo";
import PurchaseRentAddProduct from "./PurchaseRentAddProduct";
import {useI18n} from "vue-i18n/dist/vue-i18n";
import PurchaseRentGiftCard from "@/views/purchase/rent/PurchaseRentGiftCard";

export default {
    name: "Index.vue",

    components: {
        PurchaseRentGiftCard,
        PurchaseRentAddProduct,
        PurchaseCheckoutProductInfo,
        PurchaseRentPackages,
        PurchaseRentCoupon, PurchaseRentPickup, PurchaseRentCountry, PurchaseRentPeriod, AppLayout,
    },

    data() {
        return {
            loading: false
        }
    },
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },
    computed: {
        ...mapGetters({
            user: 'getUser',
            orderData: 'getOrderData',
            shipmentTypes: 'getShipmentTypes',
        }),
    },

    methods: {
        routeToCheckoutIndex() {
            let purchasingDataFilled = null
            if(this.orderData.pickup_type === 'Pickup') {
                purchasingDataFilled = this.orderData.country_id
                    && this.orderData.pickup_dt_tm
                    && this.orderData.from_date
                    && ((this.orderData.shipping_type_id && this.orderData.state)
                        || this.orderData.pickup_location_id);
                if (this.orderData.return_by_mail === true) {
                    purchasingDataFilled = purchasingDataFilled
                        && this.orderData.return_address
                        && this.orderData.return_city
                        && this.orderData.return_zip_code
                        && this.orderData.return_country
                        && this.orderData.return_state;
                }
            } else {
                purchasingDataFilled = this.orderData.country_id
                    && this.orderData.from_date
                    && this.orderData.address
                    && this.orderData.city
                    && this.orderData.zip_code
                    && ((this.orderData.shipping_type_id && this.orderData.state)
                        || this.orderData.pickup_location_id);
            }

            if (!purchasingDataFilled) {
                this.$toast.warning('Please fill all fields', {
                    position: "bottom-left",
                });
            } else {
                this.$router.push({name: 'PurchaseCheckoutIndex'})
            }
        }
    }
}
</script>

<style lang="scss">
.calendar {
    position: absolute !important;
    z-index: 10;
    top: 50px;
    left: 25px;
}
</style>