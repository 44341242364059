<template>
    <app-layout>
        <div class="container mx-auto mt-16">
            <div class="bg-white p-8 rounded-lg shadow mb-4">
                <h2 v-if="user.first_name || user.last_name" class="text-3xl font-medium mb-2">{{ user.first_name }} {{ user.last_name }}</h2>
                <p v-if="user.address" class="mb-2" ><span class="font-medium">Address:</span> {{ user.address }}</p>
                <p v-if="user.email" class="mb-2" ><span class="font-medium">Email:</span> {{ user.email }}</p>
                <p v-if="user.phone_number" class="mb-2" ><span class="font-medium">Phone number:</span> {{ user.phone_number }}</p>
            </div>
            <div class="bg-white p-8 rounded-lg shadow">
                <h2 class="text-3xl font-medium mb-2">Order history</h2>
                <div v-for="order in orders" >
                    <div class="mb-2 border border-slate-50 p-4 rounded-lg" v-if="user.api_customer_id === order.customer_api_id && order.order_status === 3">
                        <p class="font-medium">{{ order.order_id }}</p>
                        <p><span class="font-medium">Country:</span> {{ order.country_name }}</p>
                        <p><span class="font-medium">Package:</span> {{ order.country_package_name }}</p>
                        <p><span class="font-medium">Coupon code:</span> {{ order.coupon_code }}</p>
                        <p><span class="font-medium">Coupon amount:</span> {{ order.coupon_amount }}$</p>
                        <p><span class="font-medium">Start date:</span> {{ order.from_date }}</p>
                        <p><span class="font-medium">End date:</span> {{ order.to_date }}</p>
                        <p><span class="font-medium">Period:</span> {{ order.number_of_days }} days</p>
                        <p><span class="font-medium">Devices amount:</span> {{ order.number_of_devices }}</p>
                        <p><span class="font-medium">Pickup location:</span> {{ order.pickup_location_name }}</p>
                        <p><span class="font-medium">Total amount:</span> {{ order.total_amount }}$</p>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import {mapGetters} from "vuex";
export default {
    name: "Profile",
    components: {AppLayout},
    computed: {
        ...mapGetters({
            user: 'getUser',
        }),
    },
    data() {
        return {
            orders: null,
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.$axios.get(`/users/${this.user.api_customer_id}/orders`)
                .then(res => {
                    this.orders = res.data
                })
        }
    }
}
</script>

<style scoped>

</style>