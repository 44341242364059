import en from './en.json'
import jp from './jp.json'

let defaultLanguage = 'en'

const siteUrl = window.location.hostname
const regex = /^([a-z0-9]+)./gi
const res = regex.exec(siteUrl)

if (res[0] === 'lighthouse.') {
    defaultLanguage = 'jp'
} else if (res[0] === 'elephanttour.') {
    defaultLanguage = 'jp'
} else if (res[0] === 'gulliverusa.') {
    defaultLanguage = 'jp'
} else if (res[0] === 'sakura-') {
    defaultLanguage = 'jp'
} else if (res[0] === 'nta.') {
    defaultLanguage = 'jp'
} else if (res[0] === 'amnet.') {
    defaultLanguage = 'jp'
} else if (res[0] === 'kddi.') {
    defaultLanguage = 'jp'
}

export const defaultLocale = defaultLanguage

export const languages = {
    en, jp
}