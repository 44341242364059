<template>
  <div class="mt-40">
    <div class="container mx-auto px-4">
      <div class="operating-countries">
        <div>
          <hr class="w-12 mx-auto main-bg h-1 mb-4" />
          <h2 class="text-center mb-8 text-5xl blue-text">Operating Countries</h2>
        </div>
        <div class="mb-4 px-4">
          <img src="@/assets/png/skygo-wifi-map.png" alt="wifi coverage">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/ui/Button";
export default {
  name: "OperatingCountries",
  components: { Button}
}
</script>

<style lang="scss">
.operating-countries {
  h2 {
    font-weight: 700;
  }
}

</style>