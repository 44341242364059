<template>
    <Carousel class="home-slider" :settings="settings" :breakpoints="breakpoints">
        <Slide v-for="item in sliderData" :key="item.id">
            <SliderItems
                :item-data="item"
            />
        </Slide>

        <template #addons>
            <Navigation />
            <Pagination v-if="screenWidth === 768" />
        </template>
    </Carousel>
</template>

<script>
import {defineComponent} from 'vue';
import {Carousel, Navigation, Slide, Pagination} from 'vue3-carousel';
import SliderItems from "./SliderItems";
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'Breakpoints',
    components: {
        Carousel,
        Slide,
        Navigation,
        Pagination,
        SliderItems
    },
    props: {
        sliderData: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
                wrapAround: true,
                autoplay: 3000,
                pauseAutoplayOnHover: true,
            },
            breakpoints: {
                // 700px and up
                768: {
                    itemsToShow: 3,
                    snapAlign: 'start'
                },
                640: {
                    itemsToShow: 1.5,
                    snapAlign: 'start'
                }
            },
            screenWidth: 0
        }
    },
    mounted() {
        this.screenWidth = window.innerWidth;

        window.addEventListener('resize', this.updateScreenWidth);
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }
});
</script>

<style lang="scss">
.home-slider {
    padding-left: 30px;
    @media screen and (max-width: 768px) {
        padding-left: 0;
    }
    .carousel__viewport {
        margin-bottom: 70px;
        padding-bottom: 5px;

        .carousel__track {
            gap: 10px;
            @media screen and (max-width: 640px) {
                gap: 0;
            }

            .carousel__slide {
                align-items: stretch !important;
            }
        }
    }

    .carousel__prev,
    .carousel__next {
        width: 42px;
        height: 42px;
        background: #35b8f5;
        border: 1px solid #35b8f5;
        right: 50px;
        top: auto;
        bottom: -70px;
        left: auto;
        @media screen and (max-width: 400px) {
            bottom: -80px;
        }
    }

    .carousel__prev {
        right: 70px;

    }

    .carousel__prev--in-active,
    .carousel__next--in-active {
        background-color: transparent;
        color: #000;
        border: 1px solid #000;
    }

    .carousel__pagination {
        justify-content: flex-start;
        align-items: center;

        &-button {
            width: 32px;
            height: 4px;
            background-color: #c3d4da;

            &--active {
                background-color: #000;
            }
        }
    }
}

</style>
