<template>
    <app-layout>
        <hr class="w-12 main-bg h-1 mb-4 mx-auto mt-16">
        <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4">{{ $t("how_to_use.for_travel.title") }}</h1>
        <div class="container mx-auto text-center px-2">
            <div class="mb-12" v-for="item in cases" :key="item.id">
                <h2 class="text-5xl font-bold mb-6">{{ item.name }}</h2>
                <img class="mb-6" :src="require(`@/assets/png/use-cases/${item.image}`)" alt="">
                <p>{{ item.desc }}</p>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
export default {
    name: "More",
    components: {AppLayout},
    data() {
        return {
            casesData: [
                { id: 1, case: "how_to_use.more_cases.cases.case1.title", image: 'hospital-8.png', description: "how_to_use.more_cases.cases.case1.body"},
            ]
        }
    },
    computed: {
        cases() {
            return this.casesData.map(item => ({
                ...item,
                name: this.$t(item.case),
                desc: this.$t(item.description),
            }));
        }
    }
}
</script>

<style scoped>

</style>