<template>
    <app-layout>
        <hr class="w-12 main-bg h-1 mb-4 mx-auto mt-16">
        <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4">{{ $t("device_information.title") }}</h1>
        <div class="grid grid-cols-1 sm:grid-cols-2 container mx-auto gap-8">
            <img src="@/assets/png/device-info2.png" alt="">
            <img src="@/assets/png/device-info1.png" alt="">
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
export default {
    name: "DeviceInformation",
    components: {AppLayout},

}
</script>

<style scoped>

</style>