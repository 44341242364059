import moment from "moment";
import _ from "lodash";

export default {
    state: () => ({
        destinationIndex: 0,
        giftCardDiscount: 0,
        destinationCount: 0,
        popularCountries: null,
        minDates: [],
        insurance_status: true,
        // continents: [],
        countries: [],
        countryPackages: [],
        usaStates: [
            { name: "Alabama"},
            { name: "Alaska"},
            { name: "Arizona"},
            { name: "Arkansas"},
            { name: "California"},
            { name: "Colorado"},
            { name: "Connecticut"},
            { name: "Delaware"},
            { name: "Florida"},
            { name: "Georgia"},
            { name: "Hawaii"},
            { name: "Idaho"},
            { name: "Illinois"},
            { name: "Indiana"},
            { name: "Iowa"},
            { name: "Kansas"},
            { name: "Kentucky"},
            { name: "Louisiana"},
            { name: "Maine"},
            { name: "Maryland"},
            { name: "Massachusetts"},
            { name: "Michigan"},
            { name: "Minnesota"},
            { name: "Mississippi"},
            { name: "Missouri"},
            { name: "Montana"},
            { name: "Nebraska"},
            { name: "Nevada"},
            { name: "New Hampshire"},
            { name: "New Jersey"},
            { name: "New Mexico"},
            { name: "New York"},
            { name: "North Carolina"},
            { name: "North Dakota"},
            { name: "Ohio"},
            { name: "Oklahoma"},
            { name: "Oregon"},
            { name: "Pennsylvania"},
            { name: "Rhode Island"},
            { name: "South Carolina"},
            { name: "South Dakota"},
            { name: "Tennessee"},
            { name: "Texas"},
            { name: "Utah"},
            { name: "Vermont"},
            { name: "Virginia"},
            { name: "Washington"},
            { name: "West Virginia"},
            { name: "Wisconsin"},
            { name: "Wyoming"},
        ],
        shipmentTypes: [],
        pickupLocations: [],
        couponTypes: {
            1: {
                id: 1,
                tag: 'amount',
                name: 'Flat amount of'
            },
            2: {
                id: 2,
                tag: 'percent',
                name: 'Percentage of'
            },
        },
        paymentData: {
            privacy: false,
            showComponent: false,
            stripe: null,
            clientSecret: null,
            elements: null,
        },
        orderData: [
            {
                flag: null,
                // Period
                from_date: null,
                to_date: null,
                // Continent
                continent_id: null,
                continent_api_id: null,
                continent_name: null,
                // Country
                country_id: null,
                country_api_id: null,
                country_name: null,
                country_name_jp: null,
                // Country packages
                country_package_id: null,
                country_package_name: null,
                country_package_price: 0,
                offering_code: null,
                // offering_id: null,
                // Customer
                customer_id: null,
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                // Address
                address: null,
                city: null,
                country: null,
                state: null,
                zip_code: null,
                // Return Address
                return_address: null,
                return_city: null,
                return_country: null,
                return_state: null,
                return_zip_code: null,
                return_price: 15,
                return_by_mail: false,
                // Shipment
                shipping_type_id: null,
                shipping_type_api_id: null,
                shipping_type_name: null,
                shipping_type_description: null,
                shipping_type_charges: 0,
                shipping_return_price: 0,
                is_delivery: true,
                return_date: null,
                // Pickup
                pickup_location_id: null,
                pickup_location_api_id: null,
                pickup_location_name: null,
                pickup_dt_tm: null,
                pickup_time: null,
                pickup_time_note: null,
                pickup_or_delivery: null,
                pickup_type: "Pickup",
                // Coupon
                coupon_id: null,
                coupon_api_id: null,
                coupon_code: null,
                coupon_type: null,
                coupon_country_id: null,
                coupon_amount: 0,
                coupon_total_amount: 0,
                coupon_rental_days: null,
                // Insurance
                insurance_status: false,
                insurance_amount: 2,
                insurance_total_price: 0.0,

                order_id: '',
                number_of_days: 0,
                number_of_devices: 1,
                plan_amount: 0,
                total_amount: 0,

                gift_card_id: null,
            }
        ],
        deducted_amount: 0,
        selectedLanguage: 'en'
    }),

    mutations: {
        setDestinationIndex(state, index) {
            state.destinationIndex = index;
        },
        setDeductedAmount(state, index) {
            state.deducted_amount = index;
        },
        setGiftCardDiscount(state, index) {
            state.giftCardDiscount = index;
        },
        setInsuranceStatus(state, payload) {
            state.insurance_status = payload;
        },
        setMinDates(state, payload) {
            state.minDates.push(payload);
        },
        setDestinationCount(state, payload) {
            if(payload === 'minus') {
                state.destinationCount--;
            } else if (payload === 'plus'){
                state.destinationCount++;
            } else {
                state.destinationCount = payload
            }
        },
        addOrderData(state, newData) {
            state.orderData.push(newData);
        },
        setPopularCountries(state, payload) {
            state.popularCountries = payload;
        },
        // setContinents(state, payload) {
        //     state.continents = payload;
        // },
        setCountries(state, payload) {
            state.countries = payload;
        },
        setCountryPackages(state, payload) {
            state.countryPackages[state.destinationIndex] = payload;
            if(payload[0] && payload[0].country_id === state.orderData[state.destinationIndex].country_id) {
                state.countryPackages.splice(state.destinationIndex, 1, payload);
            }
        },
        setShipmentTypes(state, payload) {
            state.shipmentTypes = payload;
        },
        setPickupLocations(state, payload) {
            state.pickupLocations = payload;
        },
        setPaymentData(state, payload) {
            state.paymentData = payload;
        },
        setOrderData(state, payload) {
            state.orderData[state.destinationIndex] = payload;
        },
        setSameOrderData(state, payload) {
            const { destinationIndex, data } = payload;
            state.orderData[destinationIndex] = { ...state.orderData[destinationIndex], ...data };
        },
        clearOrderData(state, payload) {
            if (payload === 'continent' || payload === 'all') {
                state.orderData.forEach((order, i) => {
                    order.continent_id = null
                    order.continent_api_id = null
                    order.continent_name = null
                })
            }

            if (payload === 'country' || payload === 'all') {
                state.orderData.forEach((order, i) => {
                    order.country_id = null
                    order.country_api_id = null
                    order.country_name = null
                })
            }

            if (payload === 'package' || payload === 'all') {
                state.orderData.forEach((order, i) => {
                    order.country_package_id = null
                    order.country_package_name = null
                    order.country_package_price = null
                    // order.offering_id = null
                    order.offering_code = null
                })
            }

            if (payload === 'customer' || payload === 'all') {
                state.orderData.forEach((order, i) => {
                    order.customer_id = null
                    order.first_name = ''
                    order.last_name = ''
                    order.email = ''
                    order.phone_number = ''
                })
            }

            if (payload === 'address' || payload === 'all') {
                state.orderData.forEach((order, i) => {
                    order.address = null
                    order.city = null
                    order.country = null
                    order.state = null
                    order.zip_code = null
                })
            }

            if (payload === 'return' || payload === 'all') {
                state.orderData.forEach((order, i) => {
                    order.return_address = null
                    order.return_city = null
                    order.return_country = null
                    order.return_state = null
                    order.return_zip_code = null
                    order.return_by_mail = false
                })
            }

            if (payload === 'shipping' || payload === 'all') {
                state.orderData.forEach((order, i) => {
                    order.shipping_type_id = null
                    order.shipping_type_api_id = null
                    order.shipping_type_name = null
                    order.shipping_type_description = null
                    order.shipping_type_charges = 0
                    order.shipping_return_price = 0
                    order.is_delivery = true
                })
            }

            if (payload === 'pickup' || payload === 'all') {
                state.orderData.forEach((order, i) => {
                    order.pickup_location_id = null;
                    order.pickup_location_api_id = null;
                    order.pickup_location_name = null;
                    order.pickup_dt_tm = null;
                    order.pickup_time = null;
                    order.pickup_time_note = null;
                })
            }

            if (payload === 'coupon' || payload === 'all') {
                 state.orderData.forEach((order, i) => {
                    order.coupon_id = null
                    order.coupon_api_id = null
                    order.coupon_code = null
                    order.coupon_type = null
                    order.coupon_country_id = null
                    order.coupon_amount = 0
                    order.coupon_total_amount = 0
                    order.coupon_rental_days = null
                })
            }

            if (payload === 'insurance' || payload === 'all') {
                 state.orderData.forEach((order, i) => {
                    order.insurance_status = false
                    order.insurance_total_price = 2
                    order.insurance_amount = 2
                })
            }

            if (payload === 'other' || payload === 'all') {
                state.orderData.forEach((order, i) => {
                    order.order_id = ''
                    order.number_of_days = 0
                    order.number_of_devices = 1
                    order.plan_amount = 0
                    order.total_amount = 0
                })
            }
        },
        setSelectedLanguage(state, payload) {
            state.selectedLanguage = payload
        }
    },

    actions: {
        setDestinationIndex({commit}, payload) {
            commit('setDestinationIndex', payload)
        },

        setDeductedAmount({commit}, payload) {
            commit('setDeductedAmount', payload)
        },
        setGiftCardDiscount({commit}, payload) {
            commit('setGiftCardDiscount', payload)
        },
        setInsuranceStatus({commit}, payload) {
            commit('setInsuranceStatus', payload)
        },
        setMinDates({commit}, payload) {
            commit('setMinDates', payload)
        },
        setDestinationCount({commit}, payload) {
            commit('setDestinationCount', payload)
        },
        setPopularCountries({commit}, payload) {
            commit('setPopularCountries', payload);
        },
        setContinents({commit}, payload) {
            commit('setContinents', payload);
        },
        setCountries({commit}, payload) {
            commit('setCountries', payload);
        },
        setCountryPackages({commit, state, getters}, payload) {
            commit('setCountryPackages', payload);

            if (payload.length) {
                let orderData = _.cloneDeep(state.orderData[state.destinationIndex]);
                orderData.country_package_id = payload[0].id;
                orderData.country_package_name = payload[0].name;
                orderData.country_package_price = payload[0].price;
                // orderData.offering_id = payload[0].offering_id;
                orderData.offering_code = payload[0].offering_code;
                commit('setOrderData', orderData);
            }
        },
        setShipmentTypes({commit}, payload) {
            commit('setShipmentTypes', payload);
        },
        setPickupLocations({commit}, payload) {
            commit('setPickupLocations', payload);
        },
        setOrderData({commit, state, getters}, payload) {
            commit('setOrderData', payload);
            let orderData = _.cloneDeep(state.orderData[state.destinationIndex]);
            orderData.number_of_days = getters.getOrderNumberOfDays[state.destinationIndex];
            orderData.insurance_total_price = getters.getShipmentDeliveryPrice[state.destinationIndex];
            orderData.coupon_total_amount = getters.getCouponDiscountAmount[state.destinationIndex];
            orderData.plan_amount = getters.getOrderPlanAmount[state.destinationIndex];
            orderData.total_amount = getters.getOrderTotalAmount[state.destinationIndex];
            commit('setOrderData', orderData);
        },
        setSameOrderData({commit, state, getters}, payload) {
            commit('setSameOrderData', payload);
            let orderData = _.cloneDeep(state.orderData[state.destinationIndex]);
            orderData.number_of_days = getters.getOrderNumberOfDays[state.destinationIndex];
            orderData.insurance_total_price = getters.getShipmentDeliveryPrice[state.destinationIndex];
            orderData.coupon_total_amount = getters.getCouponDiscountAmount[state.destinationIndex];
            orderData.plan_amount = getters.getOrderPlanAmount[state.destinationIndex];
            orderData.total_amount = getters.getOrderTotalAmount[state.destinationIndex];
            commit('setOrderData', orderData);
        },
        setPaymentData({commit}, payload) {
            commit('setPaymentData', payload);
        },
        clearOrderData({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('clearOrderData', payload);
                resolve();
            })
        },
        setSelectedLanguage({commit}, payload) {
            commit('setSelectedLanguage', payload)
        }
    },

    getters: {
        getPopularCountries(state) {
            return state.popularCountries;
        },
        getDeductedAmount(state) {
            return state.deducted_amount;
        },
        getGiftCardDiscount(state) {
            return state.giftCardDiscount;
        },
        getInsuranceStatus(state) {
            return state.insurance_status;
        },
        getMinDates(state) {
            return state.minDates;
        },
        getDestinationCount(state) {
            return state.destinationCount;
        },
        getContinents(state) {
            return state.continents;
        },
        getCountries(state) {
            return state.countries;
        },
        getCountryPackages(state) {
            return state.countryPackages;
        },
        getUsaStates(state) {
            return state.usaStates;
        },
        getShipmentTypes(state) {
            return state.shipmentTypes;
        },
        getPickupLocations(state) {
            return state.pickupLocations;
        },
        getOrderData(state) {
            return state.orderData[0];
        },
        getAllOrderData(state) {
            return state.orderData
        },
        getOrderNumberOfDays(state) {
            return state.orderData.map((order, index) => {
                let startData = order.from_date;
                let endData = order.to_date;
                let diffDays = 0;

                if (startData && !endData) {
                    diffDays = 1;
                }

                if (startData && endData) {
                    startData = moment(startData, 'YYYY-MM-DD');
                    endData = moment(endData, 'YYYY-MM-DD');

                    if (startData.isSame(endData, 'day')) {
                        diffDays = 1;
                    } else {
                        diffDays = endData.diff(startData, 'days') + 1;
                    }
                }

                return diffDays;
            });
        },
        getCouponDiscountAmount(state, getters) {
            const price = getters.getOrderPlanAmount;
            const discounts = [];

            state.orderData.forEach((data, index) => {
                let discount = 0;
                if (data.coupon_id && (data.coupon_country_id === data.country_id || !data.coupon_country_id) && data.number_of_days >= data.coupon_rental_days) {
                    if (state.couponTypes[1].tag === data.coupon_type) {
                        discount = data.coupon_amount;
                    } else if (state.couponTypes[2].tag === data.coupon_type) {
                        discount = Math.round(price[index] * data.coupon_amount) / 100;
                    }
                }

                discounts.push(discount);
            });

            return discounts;
        },

        getInsuranceAmount(state, getters) {
            const prices = [];

            state.orderData.forEach((data, i) => {
                let price = 0;
                if (data.insurance_status) {
                    price = getters.getOrderNumberOfDays[i] * data.insurance_amount * state.orderData[0].number_of_devices;
                }

                prices.push(price);
            });
            return prices;
        },

        getShipmentDeliveryPrice(state) {
            const prices = [];

            state.orderData.forEach((data) => {
                let price = 0;

                if (data.shipping_type_id) {
                    price = data.shipping_type_charges + data.shipping_return_price;
                }

                prices.push(price);
            });

            return prices;
        },
        getReturnByMailPrice(state) {
            const prices = [];

            state.orderData.forEach((data) => {
                let price = 0;

                if (data.return_by_mail) {
                    price = data.return_price;
                }

                prices.push(price);
            });
            return prices;
        },
        getOrderPlanAmount(state, getters) {
            const orderData = _.cloneDeep(state.orderData);
            let quantity = orderData[0].number_of_devices;
            return orderData.map((data) => {
                let period = getters.getOrderNumberOfDays.find((period, i) => i === orderData.indexOf(data));
                let countryName = '';
                let price = 0;
                let priceConst = 10;

                if (data.country_package_id) {
                    priceConst = data.country_package_price;
                }

                if (data.country_id) {
                    countryName = data.country_name;
                    for (let i = 1; i <= period; i++) {
                        price += priceConst
                        // if (i <= 10) {
                        //     price += priceConst;
                        // } else if (i >= 11 && i <= 20) {
                        //     price += priceConst * 0.7;
                        // } else if (i >= 21) {
                        //     price += priceConst * 0.5;
                        // }
                    }
                }
                return Math.round((price * quantity) * 100) / 100;
            });
        },
        getOrderTotalAmount(state, getters) {
            function subtractGiftCardDiscount(finalAmounts, giftCardDiscount) {
                let remainingGiftCardDiscount = giftCardDiscount;

                return finalAmounts.map(amount => {
                    const deductedAmount = Math.min(remainingGiftCardDiscount, amount);
                    remainingGiftCardDiscount -= deductedAmount;
                    state.deducted_amount = remainingGiftCardDiscount
                    return amount - deductedAmount;
                });
            }

            const giftCardDiscount = getters.getGiftCardDiscount
            let data = state.orderData.map((order, i) => {
                const orderPlanAmount = getters.getOrderPlanAmount[i];
                const couponDiscountAmount = getters.getCouponDiscountAmount[i];
                const insuranceAmount = getters.getInsuranceAmount[i];
                const shipmentDeliveryPrice = getters.getShipmentDeliveryPrice[i];
                const returnByMailPrice = getters.getReturnByMailPrice[i];

                const data = [orderPlanAmount, insuranceAmount, shipmentDeliveryPrice, returnByMailPrice];
                const totalAmount = data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                return totalAmount - couponDiscountAmount;
            });
            data = subtractGiftCardDiscount(data, giftCardDiscount);
            return data;
        },
        getOrderTotalAmountForClient(state, getters) {
            const giftCardDiscount = getters.getGiftCardDiscount
            const finalAmounts = state.orderData.map((order, i) => {
                const orderPlanAmount = getters.getOrderPlanAmount[i];
                const couponDiscountAmount = getters.getCouponDiscountAmount[i];
                const insuranceAmount = getters.getInsuranceAmount[i];
                const shipmentDeliveryPrice = getters.getShipmentDeliveryPrice[i];
                const returnByMailPrice = getters.getReturnByMailPrice[i];

                const data = [orderPlanAmount, insuranceAmount, shipmentDeliveryPrice, returnByMailPrice];
                const totalAmount = data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                return totalAmount - couponDiscountAmount;
            });

            return finalAmounts.reduce((accumulator, currentValue) => accumulator + currentValue, 0) - giftCardDiscount;
        },
        getPaymentData(state) {
            return state.paymentData;
        },
        getSelectedLanguage(state) {
            return state.selectedLanguage;
        },
        getDestinationIndex(state) {
            return state.destinationIndex;
        },
    }
}