<template>
    <div class="mt-24">
        <div class="">
            <div>
                <div>
                    <hr class="w-12 mx-auto main-bg h-1 mb-4"/>
                    <h2 class="text-center mb-16 text-5xl blue-text font-bold">HOW TO USE</h2>
                </div>
                <div class="works-wrapper">
                    <div class="">
                        <div class="works-content">
                            <div class="container mx-auto w-full flex justify-center gap-6 flex-wrap xl:flex-nowrap">
                                <div class="bg-white flex flex-col w-[400px] p-6 rounded-lg mx-2 sm:mx-0">
                                    <iframe class="rounded-lg max-w-3xl h-80 mb-2"
                                            src="https://www.youtube.com/embed/ugSrQVYoaNs" title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                    <h2 class="font-bold text-2xl">Introducing Skygo WiFi</h2>
                                    <div class="flex items-center gap-4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                stroke="#35b8f5" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path d="M12 6V12L16 14" stroke="#35B8F5" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        <p class="blue-text font-bold text-lg">1:18 Min</p>
                                    </div>
                                </div>
                                <div class="bg-white flex flex-col w-[400px] p-6 rounded-lg mx-2 sm:mx-0">
                                    <iframe class="rounded-lg max-w-3xl h-80 mb-2"
                                            src="https://www.youtube.com/embed/lAlCzkiYQdY" title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                    <h2 class="font-bold text-2xl">How to Use U3 Device</h2>
                                    <div class="flex items-center gap-4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                stroke="#35b8f5" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path d="M12 6V12L16 14" stroke="#35B8F5" stroke-width="2"
                                                  stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        <p class="blue-text font-bold text-lg">0:58 Min</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useI18n} from "vue-i18n/dist/vue-i18n";
import Button from "@/components/ui/Button";

export default {
    name: "Media",
    components: {Button},
    setup() {
        const {t} = useI18n({
            useScope: 'global'
        })

        return {t}
    }
}
</script>

<style lang="scss">
.works-wrapper {
    //background: linear-gradient(180.02deg, rgba(230, 247, 255, 0) 12.02%, rgba(99, 205, 255, 0.7) 87.98%);
    //
    //.works-content {
    //    padding-bottom: 200px;
    //    position: relative;
    //
    //    &:before {
    //        content: '';
    //        background-image: url("../../assets/png/cloud-support.png");
    //        background-position: center;
    //        background-repeat: no-repeat;
    //        background-size: cover;
    //        position: absolute;
    //        bottom: 0;
    //        left: 0;
    //        width: 100%;
    //        height: 180px;
    //    }
    //}
}
</style>