<template>
    <app-layout>
        <hr class="w-12 main-bg h-1 mb-4 mx-auto mt-16">
        <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4">{{ $t("airport.title") }}</h1>
        <div class="grid grid-cols-1 md:grid-cols-2 container mx-auto gap-6">
            <div class="mx-auto text-center">
                <h3 class="text-xl xl:text-3xl font-bold mb-2">{{ $t("airport.locs.loc1.title") }}</h3>
                <img class="w-[400px] h-[250px] lg:w-[650px] w-full lg:h-[350px] xl:h-[450px]" src="@/assets/png/la-airport.png" alt="">
                <p class="font-semibold text-md xl:text-2xl">{{ $t("airport.locs.loc1.notes.note1") }}</p>
                <p class="font-semibold text-md xl:text-2xl">{{ $t("airport.locs.loc1.notes.note2") }}</p>
                <p class="font-semibold text-md xl:text-2xl">{{ $t("airport.locs.loc1.notes.note3") }}</p>
            </div>
            <div class="mx-auto text-center">
                <h3 class="text-xl xl:text-3xl font-bold mb-2">{{ $t("airport.locs.loc2.title") }}</h3>
                <img class="w-[400px] h-[250px] lg:w-[650px] w-full lg:h-[350px] xl:h-[450px]" src="@/assets/png/sa-airport.jpeg" alt="">
                <p class="font-semibold text-md xl:text-2xl">{{ $t("airport.locs.loc2.notes.note1") }}</p>
                <p class="font-semibold text-md xl:text-2xl">{{ $t("airport.locs.loc2.notes.note2") }}</p>
                <p class="font-semibold text-md xl:text-2xl">{{ $t("airport.locs.loc2.notes.note3") }}</p>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
export default {
    name: "Airport",
    components: {AppLayout}
}
</script>

<style scoped>

</style>