<template>
<app-layout>
    <hr class="w-12 main-bg h-1 mb-4 mx-auto mt-16">
    <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4">Blog</h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 container mx-auto mt-16 px-4">
        <BlogPost
            v-if="selectedLang === 'jp'"
            :post="posts[0]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/blog-foods-1.webp" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'en'"
            :post="posts[1]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/blog-en-plane.webp" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'jp'"
            :post="posts[2]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/fr-food-1.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'jp'"
            :post="posts[3]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/rie-4.webp" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'jp'"
            :post="posts[4]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/chick.webp" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'en'"
            :post="posts[5]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/korea-blog.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'jp'"
            :post="posts[6]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/desney-main.webp" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'en'"
            :post="posts[7]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/iceland-chest.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'jp'"
            :post="posts[8]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/disney-2-mickey.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'jp'"
            :post="posts[9]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/jal-plane.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'en'"
            :post="posts[10]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/jal-plane.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'en'"
            :post="posts[11]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/santa-monica.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'jp'"
            :post="posts[12]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/santa-monica.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'en'"
            :post="posts[13]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/back-on.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'jp'"
            :post="posts[14]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/back-on.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'en'"
            :post="posts[15]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/port-santa-monica.jpg" alt="">
        </BlogPost>
        <BlogPost
            v-if="selectedLang === 'jp'"
            :post="posts[16]"
        >
            <img class="h-[364px] w-[484px] object-cover" src="../assets/png/port-santa-monica.jpg" alt="">
        </BlogPost>
    </div>
</app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import BlogPost from "@/components/ui/BlogPost";
import {mapGetters} from "vuex";
export default {
    name: "Blog",
    components: {BlogPost, AppLayout},
    computed: {
        ...mapGetters({
            selectedLang: 'getSelectedLanguage'
        }),
    },
    data() {
        return {
            posts: [
                {title: '初！アメリカへの旅立ち', desc: 'Skygo、ブログをはじめましたっ！記念すべき最初の投稿者は、、、　インターン生のRieです！ みなさん、はじめまして^ ^', id: 1},
                {title: 'My Trip to East Asia', desc: 'Japan consists of 6,852 islands and they were once attached to the eastern coast of Asia until tectonic plate shifting caused them to split and formed the sea of Japan. Japan has the 11th largest population in the world and has over 125 Million citizens.', id: 2},
                {title: '大好きなレストラン🍽', desc: 'みなさん、こんにちは！Skygoインターン生のRieです^ ^', id: 3},
                {title: 'まったり一人旅　～ポーランド　ワルシャワ～', desc: 'みなさん、こんにちは☀ Sky Goインターン生のRieです^ ^', id: 4},
                {title: 'みなさん、こんにちは！Sky Goインターン生のRieです^ ^', desc: 'わたし、BUMP OF CHICKENのファンだったりで、かれこれ10年以上っ', id: 5},
                {title: 'Korean seafood with the ocean view 🐟🦀', desc: 'Hello, I’m Rie ^ ^\n' +
                        'I’m from Japan for an internship at Skygo.\n' +
                        '\n' +
                        'I’d like to introduce a Korean restaurant where you can eat Delicious seafood.🔥And let me tell you how much I love rice…!\n', id: 6},
                {title: 'カリフォルニア ディズニー Part１🐭🏰', desc: 'みなさん、こんにちは！\n' +
                        'Skygoインターン生のRieです^ ^\n', id: 7},
                {title: 'Iceland: The Land of Ice and Fire', desc: 'Iceland is a Nordic country filled with beautiful landscapes that are unmatched and uniquely different from other regions around the world.', id: 8},
                {title: 'カリフォルニア ディズニー Part２🐭🏰\n', desc: 'みなさん、こんにちは！\n' +
                        'Skygoインターン生のRieです^ ^\n', id:9},
                {title: 'インターン留学を終えて、、、✈', desc: 'みなさん、こんにちは！\n' +
                        'Skygoインターン生のRieです^ ^\n', id:10},
                {title: 'After completing my internship, ✈', desc: 'Hello everyone!\n' +
                        'I\'m Rie, a Skygo intern ^ ^\n', id:11},
                {title: 'Hello!!✋I’m Koki from Kansai in Japan and I started my summer Internship at skygo for three weeks.', desc: '📶Now, I’d like to introduce my trip in L.A🤩 with skygo portable wifi. I went to lots of sightseeings there such as Disneyland, Santa Monica beach and Angels stadium.', id:12},
                {title: 'こんにちは！✋skygoで3週間のサマーインターン中の関西出身のコウキです', desc: '📶今回は、skygoモバイルwifiを利用したLA観光を紹介したいと思います！この期間中に、ディズニーランドやサンタモニカビーチ、エンジェルススタジアムなどたくさんの観光地に行ってきました。本当に充実した時間で人生最高の夏休みになりました！\n', id:13},
                {title: 'Hello! I’m Kairi! I’m from Japan through a program for college internship.🇯🇵', desc: 'This is my first visit abroad so I was really nervous but I’m looking forward to seeing everything! ', id:14},
                {title: 'こんにちは、カイリです！🇯🇵 ', desc: '今回が初めての海外なのでとても緊張していますが、いろいろなものを見るのが楽しみです！それから何でも知るためにはネットワークが欠かせません。 ', id:15},
                {title: 'Hi there! What\'s your plan for the trip? ', desc: ' I love traveling the world! And my nice life in LA is going on! ', id:16},
                {title: 'みなさんこんにちは！', desc: '今年の夏はどのように過ごしていますか？私は現在Skygoのインターンとして活動しています！ ', id:17},
            ]
        }
    },

}
</script>

<style scoped>

</style>