import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/index.css'
import VueSmoothScroll from 'vue3-smooth-scroll'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import mitt from 'mitt';
import Toaster from "@meforma/vue-toaster";

import {axiosPlugin} from "@/plugins/axios"
import i18n from './i18n'

const app = createApp(App).use(i18n);
const emitter = mitt();

app.config.globalProperties.emitter = emitter;

app.use(store)
    .use(router)
    .use(axiosPlugin)
    .use(VueSmoothScroll, {
        updateHistory: false
    })
    .use(VCalendar, {})
    .use(Toaster)
    .mount('#app')
