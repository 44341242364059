<template>
    <div class="app-layout">
        <login-modal />

        <ScrollToTop />
        <Navbar />

        <slot></slot>

        <Contacts />
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from "../components/Footer";
import ScrollToTop from "../components/ui/ScrollToTop";
import Contacts from "../views/home-pages/Contacts";
import LoginModal from "@/views/auth/modal/LoginModal";

export default {
    components: {
        LoginModal,
        Contacts,
        ScrollToTop,
        Footer,
        Navbar
    }
}
</script>

<style lang='scss'>
</style>