<template>
<app-layout>
    <main class="relative">
        <div class="container mx-auto">
            <div class="text-center py-32">
                <h1 class="text-center text-4xl sm:text-6xl text-white mb-6 ">
                    <span>Skygo WiFi</span>
                </h1>
                <span
                    class="text-center text-4xl sm:text-6xl text-white font-semibold"
                >Stay Connected in Japan</span>
                <img class="mx-auto w-1/4" src="@/assets/png/pro.png" alt="">
                <p class="text-xl text-white my-8">
                    Skygo WiFi will keep You Connected While You Travel In Japan
                </p>
                <purchasing-bar />
            </div>
        </div>
        <div class="japan-bg"></div>
    </main>
    <div class="lax relative">
        <div class="container mx-auto">
            <div class="">
                <p class="text-4xl sm:text-5xl font-bold text-white text-center pt-32">PICKUP AT LAX</p>
                <div class="mx-2 sm:mx-0 flex justify-evenly gap-8 lg:gap-0 items-start cart-wrapper py-8 sm:py-16 lg:py-28 px-6 flex-wrap lg:flex-nowrap">
                    <div class="max-w-sm">
                        <p class="text-white text-3xl sm:text-4xl text-center font-semibold mb-6">
                            ICE CURRENCY
                            <br />
                            EXCHANGE BOOTH
                            <br />
                            TERMINAL B
                        </p>
                        <p class="text-white text-3xl sm:text-4xl text-center font-semibold">
                            FLOOR 1 ARRIVALS
                            FLOOR 3 TICKETING
                        </p>
                    </div>
                    <div>
                        <img class="w-auto md:max-w-[500px] w-auto" src="@/assets/png/lax-currency.jpeg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="lax-japan-bg"></div>
    </div>
    <Reviews />
    <Location />
</app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import PurchasingBar from "@/views/purchase/_modules/purchasing-bar/PurchasingBar";
import Lax from "@/views/home-pages/Lax";
import Reviews from "@/views/home-pages/Reviews";
import Location from "@/views/home-pages/Location";
export default {
    name: "Japan",
    components: {Location, Reviews, Lax, PurchasingBar, AppLayout}
}
</script>

<style scoped>

</style>