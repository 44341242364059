<template>
    <button
        :class="btnStyles()"
        :disabled="disabled"

    >
        <template v-if="!loading">
            {{ text }}
        </template>

        <template v-if="loading">
            Loading...
        </template>

        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "Button",

    props: {
        text: {
            type: String,
            default() {
                return 'Button'
            }
        },
        color: {
            type: String,
            default() {
                return 'primary'
            }
        },
        size: {
            type: String,
            default() {
                return 'sm'
            }
        },
        center: {
            type: Boolean,
            default() {
                return false
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false
            }
        },

    },

    methods: {
        btnStyles() {
            let styles = ` btn pointer capitalize text-center flex items-center gap-4`
            if (this.color === 'primary') {
                styles += ` main-bg  text-white  border-none`
            }
            if (this.color === 'blue') {
                styles += ` blue-bg  text-white  border-none`
            }
            if (this.color === 'none') {
                styles += ` bg-none text-black border border-black`
            }
            if (this.color === 'hover') {
                styles += ` bg-white border border-2 border-sky-300 hover:bg-sky-300 transition hover:text-white`
            }
            if (this.size === 'sm') {
                styles += ` py-2 px-9 rounded-3xl`
            }
            if (this.size === 'lg') {
                styles += ` py-3 px-9 rounded-full`
            }

            if (this.center) {
                styles += ` mx-auto`
            }
            return styles
        }
    }
}
</script>

<style lang="scss">
button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
</style>