<template>
  <app-layout>
    <div class="works-bg">
      <h1 class="text-center text-white text-5xl font-bold px-4">{{ $t('how_it_works.title') }}</h1>
    </div>
    <Steps />
    <Media />
  </app-layout>
</template>

<script>
import AppLayout from "../layouts/AppLayout";
import Steps from "./home-pages/Steps";
import Media from "./home-pages/Media";
import {useI18n} from "vue-i18n/dist/vue-i18n";

export default {
  name: "Works",
  components: {Media, Steps, AppLayout},
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    }
}
</script>

<style lang="scss">
.works-bg {
  background-image: url("../../src/assets/png/how-its-work-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 170px 0;
}
</style>