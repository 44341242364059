<template>
    <modal
        :name="modalName"
    >
        <div class="bg-white mx-auto w-1/3 rounded-xl mt-40 px-8 py-4 border-4 border-sky-300">
            <div class="w-full flex justify-between">
                <h1 class="text-2xl font-semibold mb-8">Login to Rent</h1>

                <button
                    class="bg-white border-2 border-orange-500 rounded-full h-8 w-8"
                    @click="hideModal"
                >
                    <img
                        class="w-4 m-auto"
                        src="../../../assets/png/reject-orange.png"
                        alt=""
                    >
                </button>
            </div>

            <div class="w-full">
                <input-text
                    type="email"
                    label="Email"
                    :error-message="errors && errors.email ? errors.email[0] : ''"
                    v-model="form.email"
                />
            </div>

            <div class="w-full">
                <input-text
                    type="password"
                    label="Password"
                    :error-message="errors && errors.password ? errors.password[0] : ''"
                    v-model="form.password"
                />
            </div>

            <div class="w-full">
                <button
                    v-if="!loading"
                    class="btn pointer capitalize text-center flex items-center main-bg text-white border-none py-2 px-9 rounded-xl"
                    @click="submit"
                >
                    Login to rent
                </button>

                <button
                    v-if="loading"
                    class="btn pointer capitalize text-center flex items-center main-bg text-white border-none py-2 px-9 rounded-xl"
                    disabled
                >
                    Loading...
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/ui/modal/Modal";
import InputText from "@/components/ui/inputs/InputText";
import { $vfm } from 'vue-final-modal'

export default {
    name: "LoginModal",

    components: {InputText, Modal},

    data() {
        return {
            modalName: 'LoginModal',
            form: {
                email: '',
                password: '',
            },
            errors: null,
            loading: false,
            routeToName: null
        }
    },

    created() {
        this.emitter.on(`show${this.modalName}`, this.openModal);
        this.emitter.on(`hide${this.modalName}`, this.hideModal);
    },

    beforeUnmount() {
        this.emitter.off(`show${this.modalName}`, this.openModal);
        this.emitter.off(`hide${this.modalName}`, this.hideModal);
    },

    methods: {
        openModal(data) {
            if (data && data.routeToName) {
                this.routeToName = data.routeToName;
            }

            this.emitter.emit(`modal::show::${this.modalName}`);
        },

        hideModal() {
            this.emitter.emit(`modal::hide::${this.modalName}`);
        },

        submit() {
            this.loading = true;
            this.errors = null;

            this.$axios.post(`/login`, this.form)
                .then(res => {
                    if (res.data.token) {
                        this.$store.dispatch('setUser', res.data.user);
                        localStorage.setItem("access-token", res.data.token);
                        this.$store.dispatch("loadMe");

                        if (this.routeToName) {
                            this.$router.push({name: this.routeToName});
                        }
                    }
                    this.loading = false;
                    this.emitter.emit(`modal::hide::LoginModal`);

                    this.$toast.info('You have logged in successfully', {
                        position: "bottom-left",
                    });
                })
                .catch(e => {
                    if (e.status === 422) {
                        this.errors = e.data.errors;
                    }

                    this.$toast.warning(e.data.message, {
                        position: "top",
                    });

                    this.loading = false;
                });
        },
    }
}
</script>

<style scoped>

</style>