<template>
    <app-layout>
        <hr class="w-12 main-bg h-1 mb-4 mx-auto mt-16">
        <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4">More Locations</h1>
        <div class="grid grid-cols-1 md:grid-cols-2 container mx-auto gap-6">
            <div class="mx-auto text-center" v-for="loc in moreLocs">
                <h3 class="text-xl xl:text-3xl font-bold mb-2">{{ loc.title }}</h3>
                <img class="w-[400px] h-[250px] lg:w-[650px] w-full lg:h-[450px]" :src="require(`@/assets/png/more-locs/${loc.image}`)" alt="">
                <p v-for="locNote in loc.notes" class="font-semibold text-md xl:text-2xl max-w-sm md:max-w-xl">{{ locNote }}</p>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
export default {
    name: "MoreLocations",
    components: {AppLayout},
    data() {
        return {
            moreLocs: [
                {
                    id: 1,
                    title: this.$t("more_locs.locs.loc1.title"),
                    notes: [
                        this.$t("more_locs.locs.loc1.working_hours"),
                        this.$t("more_locs.locs.loc1.phone"),
                        this.$t("more_locs.locs.loc1.address")
                    ],
                    image: 'torrance-office.jpg'
                },
                {
                    id: 2,
                    title: this.$t("more_locs.locs.loc2.title"),
                    notes: [
                        this.$t("more_locs.locs.loc2.working_hours"),
                        this.$t("more_locs.locs.loc2.phone"),
                        this.$t("more_locs.locs.loc2.address")
                    ],
                    alert: this.$t("more_locs.locs.loc2.alert"),
                    image: 'elephant-loc.jpeg'
                },
                {
                    id: 3,
                    title: this.$t("more_locs.locs.loc3.title"),
                    notes: [
                        this.$t("more_locs.locs.loc3.working_hours"),
                        this.$t("more_locs.locs.loc3.phone"),
                        this.$t("more_locs.locs.loc3.address")
                    ],
                    image: 'gul-tor-loc.jpeg'
                },
                {
                    id: 4,
                    title: this.$t("more_locs.locs.loc4.title"),
                    notes: [
                        this.$t("more_locs.locs.loc4.working_hours"),
                        this.$t("more_locs.locs.loc4.phone"),
                        this.$t("more_locs.locs.loc4.address")
                    ],
                    image: 'gul-irvine-loc.jpeg'
                },
                // {
                //     id: 5,
                //     title: this.$t("more_locs.locs.loc5.title"),
                //     notes: [
                //         this.$t("more_locs.locs.loc5.working_hours"),
                //         this.$t("more_locs.locs.loc5.address")
                //     ],
                //     image: 'tokyo-loc.jpeg'
                // },
                {
                    id: 6,
                    title: this.$t("more_locs.locs.loc6.title"),
                    notes: [
                        this.$t("more_locs.locs.loc6.working_hours"),
                        this.$t("more_locs.locs.loc6.sunday"),
                        this.$t("more_locs.locs.loc6.address")
                    ],
                    image: 'daido-loc.jpeg'

                },
            ]
        }
    }
}
</script>

<style scoped>

</style>