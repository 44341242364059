<template>
    <app-layout>
        <hr class="w-12 main-bg h-1 mb-4 mx-auto mt-16">
        <div class="container mx-auto" v-if="!loading.countries">
            <input-select
                :placeholder="$t('order.select_plan')"
                select-property-name="id"
                :options="countries"
                v-model="orderData.country_name"
                @input="inputSelectCountry"
                @selected="selectCountry"
                @open="loadCountries"
                @close="() => {this.selectCountryKeywords = ''}"
            />
        </div>
        <h1 class="blue-text text-5xl font-bold text-center mb-4 px-4">{{ $t("prices.title") }}</h1>
        <div class="container mx-auto px-4">
            <div class="pt-16 pb-8 px-4 md:px-0">
                <popular-countries />
                <div
                    class="flex items-start gap-0 md:gap-56 flex-wrap md:flex-nowrap "
                >
                    <div class="w-full md:w-[75%] mx-auto">
                        <template v-if="loading.countries">
                            <div class="w-full flex justify-center">
                                <spinner />
                            </div>
                        </template>
                    </div>
                </div>
                <div
                    v-if="orderData.country_name"
                    class="relative border-2 md:w-1/5 border-sky-300 rounded-lg pt-4 pb-2 text-center cursor-pointer mx-auto w-full "
                    @mouseenter="isBoxActive = true"
                    @mouseleave="isBoxActive = false"
                    @click="isClicked = !isClicked"
                >
                    <img
                        class="w-20 mx-auto rounded-lg"
                        :src="require('../../assets/svg/flags/' + orderData.flag) "
                    >
                    <p class="mt-4 text-lg">
                        {{ orderData.country_name }}
                    </p>
                    <div
                        v-if="isBoxActive || isClicked"
                        class="absolute w-full h-full top-0 left-0 bg-sky-300 cursor-pointer text-white py-2"
                    >
                        <p class="font-bold text-sm">{{ orderData.country_name }} Pricing</p>
                        <div
                            class="grid"
                            :class="countryPackages[0].length > 2 ? 'grid-cols-2' : 'grid-cols-1'"
                        >
                            <div v-for="item in countryPackages[0]">
                                <div class="mb-0.5 text-sm">
                                    <p class="font-semibold">{{item.name}}</p>
                                    <p>${{item.price}}/day</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import PopularCountries from "@/views/purchase/_modules/popular-countries/PopularCountries";
import _ from "lodash";
import {mapGetters} from "vuex";
import InputSelect from "@/components/ui/inputs/InputSelect";
import Spinner from "@/components/ui/Spinner";
export default {
    name: "Prices",
    components: {Spinner, InputSelect, PopularCountries, AppLayout},
    data() {
        return {
            isBoxActive: false,
            isClicked: false,
            currentFlag: null,
            loading: {
                popularCountries: false,
                continents: false,
                countries: false,
            },
            selectContinentKeywords: '',
            selectCountryKeywords: '',
            selectedContinent: null,
            selectedCountry: null,
        }
    },
    computed: {
        ...mapGetters({
            orderData: 'getOrderData',
            continents: 'getContinents',
            countries: 'getCountries',
            popularCountries: 'getPopularCountries',
            selectedLanguage: 'getSelectedLanguage',
            allOrderData: 'getAllOrderData',
            destinationIndex: 'getDestinationIndex',
            countryPackages: 'getCountryPackages',
            destinationsCount: 'getDestinationCount',
            minDates: 'getMinDates'
        }),
    },
    methods: {
        loadContinents() {
            this.$axios.get(`/continents/search`, {
                params: {
                    limit: 10,
                    keywords: this.selectContinentKeywords
                }
            })
                .then(res => {
                    this.$store.dispatch('setContinents', res.data)
                    this.continents.forEach(continent => {
                        if(this.selectedLanguage === 'jp' && continent.name_jp) {
                            continent.name = continent.name_jp
                        }
                    })
                });
        },

        loadCountries() {
            let url = `/countries/search`

            this.$axios.get(url, {
                params: {
                    keywords: this.selectCountryKeywords
                }
            })
                .then(res => {
                    this.$store.dispatch('setCountries', res.data)
                    this.countries.forEach(country => {
                        if(this.selectedLanguage === 'jp' && country.name_jp) {
                            country.name = country.name_jp
                        }
                    })
                });
        },

        inputSelectCountry: _.debounce(function (e) {
            this.selectCountryKeywords = e && e.target.value ? e.target.value : '';
        }, 300),

        selectCountry(data) {
            this.$store.dispatch('setDestinationIndex', 0)
            this.$store.dispatch('clearOrderData', 'package');
            this.$store.dispatch('clearOrderData', 'coupon');
            this.$store.dispatch('clearOrderData', 'insurance');
            this.$store.dispatch('clearOrderData', 'other');
            let orderData = _.cloneDeep(this.orderData);
            orderData.country_id = data.id;
            orderData.country_api_id = data.api_country_id;
            orderData.country_name = data.name;
            orderData.country_name_jp = data.name_jp;
            orderData.continent_id = data.continent_id;
            orderData.continent_api_id = data.api_continent_id;
            orderData.continent_name = data.continent_name;
            orderData.flag = data.img;
            this.$store.dispatch('setOrderData', orderData);
            this.loadCountryPackages(data.id);
        },

        loadCountryPackages(countryId) {
            this.$axios.get(`/countries/${countryId}/get-country-packages`)
                .then(res => {
                    this.$store.dispatch('setCountryPackages', res.data);
                });
        },
    }
}
</script>

<style scoped>

</style>