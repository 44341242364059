<template>
    <div class="flex">
        <button @click="dropdownToggle" class="text-black font-medium rounded-lg text-sm text-center inline-flex items-center gap-2 justify-center">
            <img v-if="selectedLanguage ==='en'" src="@/assets/png/united-states.png" alt="">
            <img v-if="selectedLanguage ==='jp'" src="@/assets/png/japan.png" alt="">
            <p>{{languages[selectedLanguage].name}}</p>
        </button>

        <div v-if="dropdownOpen" class="z-10 absolute bg-white divide-y divide-gray-100 rounded shadow w-16 bottom-6 right-16 md:bottom-5 md:right-3">
            <ul class="py-1 px-2 text-sm text-gray-700 flex justify-center flex-col items-center gap-2">
                <li @click="selectLanguage('en')" class="flex gap-2 cursor-pointer">
                    <img src="@/assets/png/united-states.png" alt="">
                    EN
                </li>
                <li @click="selectLanguage('jp')" class="flex gap-2 cursor-pointer">
                    <img src="@/assets/png/japan.png" alt="">
                    JP
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {mapGetters} from "vuex";
export default {
  name: "ChangeLanguageDropdown",
    computed: {
        ...mapGetters({
            selectedLanguage: 'getSelectedLanguage',
        }),
    },
    data() {
        return {
            dropdownOpen: false,
            languages: {
                en: { code: 'en', name: 'EN' },
                jp: { code: 'jp', name: 'JP' },
            },
        }
    },
    methods: {
        dropdownToggle() {
            this.dropdownOpen  = !this.dropdownOpen
        },
        selectLanguage(lang) {
            this.$store.dispatch('setSelectedLanguage', lang)
            this.switchLang(lang)
            this.dropdownOpen = false
        }
    },
    created() {
        this.$store.dispatch('setSelectedLanguage', localStorage.getItem('lang') || 'en')
    },
    setup() {
        const {locale} = useI18n({useScope: 'global'})

        const switchLang = (language) => {
            locale.value = language
            localStorage.setItem('lang', locale.value)
        }

        return {switchLang}
  }
}
</script>

<style lang="scss">
</style>