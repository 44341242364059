<template>
    <app-layout>
        <div class="w-full flex justify-center bg-white">
            <div class="py-32">
                <template
                    v-if="resulTitle"
                >
                    <h1 class="text-5xl font-semibold mb-10 text-center text-sky-300">
                        {{ resulTitle }}
                    </h1>
                </template>

                <template
                    v-if="resulText"
                >
                    <h1 class="text-2xl font-semibold mb-10 text-center text-orange-500">
                        {{ resulText }}
                    </h1>
                </template>

                <template
                    v-if="resulButtonText"
                >
                    <button
                        class="btn pointer capitalize text-center text-xl font-semibold flex items-center gap-4 main-bg m-auto text-white border-none py-4 px-10 rounded-3xl"
                        @click="goToRoute"
                    >
                        {{ resulButtonText }}
                    </button>
                </template>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import {mapGetters} from "vuex";

export default {
    name: "Result",

    components: {AppLayout},

    data() {
        return {

        }
    },

    computed: {
        ...mapGetters({
            authResultType: 'getAuthResultType',
        }),

        resulTitle() {
            switch (this.authResultType) {
                case 'home':
                    return 'Welcome to Skygo WiFi';
                case 'logout':
                    return 'Sorry you are leaving';
                case 'register':
                    return 'Welcome to Skygo WiFi';
                case 'purchase-checkout':
                    return 'Welcome to Skygo WiFi';
                case 'reset-password':
                    return 'Welcome to Skygo WiFi';
                default:
                    return null;
            }
        },

        resulText() {
            switch (this.authResultType) {
                case 'home':
                    return 'You logged in successfully!';
                case 'logout':
                    return 'You logged out successfully!';
                case 'register':
                    return 'You have registered successfully!';
                case 'purchase-checkout':
                    return 'You logged in successfully!';
                case 'reset-password':
                    return 'The password was successfully changed';
                default:
                    return null;
            }
        },

        resulButtonText() {
            switch (this.authResultType) {
                case 'home':
                    return 'Go to Home page';
                case 'logout':
                    return 'Go to Login page';
                case 'register':
                    return 'Go to Login page';
                case 'purchase-checkout':
                    return 'Return to checkout';
                case 'reset-password':
                    return 'Go to Login page';
                default:
                    return null;
            }
        },
    },

    methods: {
        goToRoute() {
            switch (this.authResultType) {
                case 'home':
                    this.$router.push({name: 'Home'});
                    break;
                case 'logout':
                    this.$store.dispatch('setAuthResultType', 'home');
                    this.$router.push({name: 'Login'});
                    break;
                case 'register':
                    this.$store.dispatch('setAuthResultType', 'home');
                    this.$router.push({name: 'Login'});
                    break;
                case 'reset-password':
                    this.$store.dispatch('setAuthResultType', 'reset-password');
                    this.$router.push({name: 'Login'});
                    break;
                case 'purchase-checkout':
                    this.$router.push({name: 'PurchaseCheckoutIndex'});
            }
        }
    }
}
</script>

<style scoped>

</style>