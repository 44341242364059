<template>
  <app-layout>
    <OperatingCountries />

    <div class="mt-40">
      <div class="w-1/3 mx-auto">
        <InputText
          placeholder="Search..."
        />
      </div>
      <Countries />
    </div>

  </app-layout>
</template>

<script>
import AppLayout from "../layouts/AppLayout";
import OperatingCountries from "./home-pages/OperatingCountries";
import Countries from "./home-pages/Countries";
import TextInput from "../components/ui/TextInput";
import InputText from "../components/ui/inputs/InputText";
export default {
  name: "Plans",
  components: {InputText, TextInput, Countries, OperatingCountries, AppLayout},

}
</script>

<style scoped>

</style>