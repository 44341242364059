<template>
    <div
        class="text-left border-sky-300 border-b-2 lg:border-b-0 pb-1"
    >
        <h5 class="text-left blue-text font-semibold">Destination</h5>
        <p
            v-if="!purchasingData.destination.open"
            class="text-left font-normal blue-text cursor-pointer"
            @click="toggleSelectDestination"
        >
            {{ selectedDestination.id ? selectedDestination.name : 'Add Destination' }}
        </p>

        <input
            v-if="purchasingData.destination.open"
            ref="payment-bar-destination-input"
            type="text"
            class="payment-bar__destination-input"
            placeholder="Select destination"
            v-model="selectedDestination.name"
            @input="inputDestination"
            @click="toggleSelectDestination"
        >

        <ul
            v-if="purchasingData.destination.open"
            class="payment-bar__destination-list absolute rounded-2xl bg-white w-full max-w-sm left-0 px-4 py-2 cursor-pointer"
        >
            <template
                v-for="destination in destinations"
            >
                <li
                    class="p-2"
                    @click="selectDestination(destination)"
                >
                    {{ destination.name }}
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import {nextTick} from "vue";

export default {
    name: "PurchasingBarDestination",

    data() {
        return {
            loading: false,
            destinations: [],
            destinationKeywords: '',
            selectedDestination: {},
        }
    },

    computed: {
        ...mapGetters({
            purchasingData: 'getPurchasingData',
        })
    },

    created() {
        this.loadDestinations();
    },

    methods: {
        loadDestinations() {
            this.loading = true;

            this.$axios.get(`/countries/search`, {
                params: {
                    limit: 5,
                    keywords: this.destinationKeywords
                }
            })
                .then(res => {
                    this.destinations = res.data;
                    this.loading = false;
                })
                .catch(e => {
                    this.loading = false;
                });
        },

        async toggleSelectDestination() {
            let purchasingData = _.cloneDeep(this.purchasingData);
            purchasingData.destination.open = !purchasingData.destination.open;
            purchasingData.startDate.open = false;
            purchasingData.endDate.open = false;

            this.$store.dispatch('setPurchasingDestination', purchasingData);

            await nextTick();

            if (purchasingData.destination.open) {
                let destinationInput = this.$refs['payment-bar-destination-input'];
                destinationInput.focus()
            }
        },

        inputDestination: _.debounce(function (e) {
            if (e && e.target.value) {
                this.destinationKeywords = e.target.value;
                this.loadDestinations();
            }
        }, 500),

        selectDestination(destination) {
            this.selectedDestination = destination;
            let purchasingData = _.cloneDeep(this.purchasingData);
            purchasingData.destination.open = false;

            if (destination) {
                purchasingData.destination.value = destination;
            }

            this.$store.dispatch('setPurchasingDestination', purchasingData);
        }
    }
}
</script>

<style lang="scss">
.payment-bar {
    &__destination {

    }

    &__destination-input {
        background: #fff;
        caret-color: #35b8f5;
        border: none;
        outline: none;
        color: #35b8f5;
    }

    &__destination-list {
        border: 3px solid #35b8f5;
        top: 5.5rem;
        z-index: 10;

        li {
            background: #fff;
            transition: .1s;

            &:hover {
                background: rgba(0,0,0,0.1);
            }
        }
    }
}
</style>