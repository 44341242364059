<template>
  <div class="mb-6">
    <input
        type="tel"
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :placeholder="placeholder"
        required
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    >
      <p class="mt-2 text-sm text-red-600" v-if="errorMessage">
          {{ errorMessage }}
      </p>
  </div>
</template>

<script>
export default {
  name: "NumberInput",
    props: {
        placeholder: {
            type: String,
            default() {
                return 'Mobile No.'
            }
        },
        modelValue: {
            type: String,
            default: null,
        },
        errorMessage: {
            type: String,
            default() {
                return "";
            },
        },
    }
}
</script>

<style lang="scss">

</style>