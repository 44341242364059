<template>
    <div class="pt-16 pb-8 px-4 md:px-0">
        <p class="text-3xl font-semibold mb-8 text-left text-black">
            {{ $t('order.choose_popular') }}
        </p>

        <popular-countries />

        <div
            class="flex items-start gap-0 md:gap-56 flex-wrap md:flex-nowrap "
        >
            <div class="w-full md:w-1/2">
                <div class="mb-4">
                    <span>Don't see your destination? Search here.</span>
                </div>

                <template v-if="loading.countries">
                    <div class="w-full flex justify-center">
                        <spinner/>
                    </div>
                </template>

                <template v-if="!loading.countries">
                    <input-select
                        :placeholder="$t('order.select_plan')"
                        select-property-name="id"
                        :options="countries"
                        v-model="orderData.country_name"
                        @input="inputSelectCountry"
                        @selected="selectCountry"
                        @open="loadCountries"
                        @close="() => {this.selectCountryKeywords = ''}"
                    />
                </template>
            </div>
        </div>
        <div>
            <div
                v-if="orderData.country_name"
                class="relative border-2 w-full md:w-1/5 border-sky-300 rounded-lg pt-4 pb-2 text-center cursor-pointer mb-4"
                @mouseenter="isBoxActive = true"
                @mouseleave="isBoxActive = false"
                @click="isClicked = !isClicked"
            >
                <img
                    class="w-20 mx-auto rounded-lg"
                    :src="require('../../../assets/svg/flags/' + orderData.flag) "
                >
                <p class="mt-4 text-lg">
                    {{ orderData.country_name }}
                </p>
                <div
                    v-if="isBoxActive || isClicked"
                    class="absolute w-full h-full top-0 left-0 bg-sky-300 cursor-pointer text-white py-2"
                >
                    <p class="font-bold text-sm">{{ orderData.country_name }} Pricing</p>
                    <div
                        class="grid"

                        :class="countryPackages[0].length > 2 ? 'grid-cols-2' : 'grid-cols-1'"
                    >
                        <div v-for="item in countryPackages[0]">
                            <div class="mb-0.5 text-sm">
                                <p class="font-semibold">{{item.name}}</p>
                                <p class="">${{item.price}}/day</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-for="destination in destinationsCount">
            <div class="flex justify-between md:flex-nowrap flex-wrap items-end gap-6 mb-6">
                <div class="flex items-center justify-between w-full gap-2">
                    <input-select
                        :placeholder="$t('order.select_plan')"
                        select-property-name="id"
                        :options="countries"
                        v-model="allOrderData[destination].country_name"
                        @input="inputSelectCountry"
                        @selected="selectOtherCountries($event, destination)"
                        @open="loadCountries"
                        @close="() => {this.selectCountryKeywords = ''}"
                        class="mb-0 w-full"
                    />
                    <img @click="deleteDestination(destination)" class="w-3 h-3 mb-0 md:mb-5 md:hidden " src="@/assets/png/x-mark.png" alt="">
                </div>
                <div class="purchase-rent-period__calendars w-full flex flex-wrap md:flex-nowrap">
                    <div class="purchase-rent-period__calendar w-full m-auto mb-4 md:mb-auto md:mr-8 md:w-full md:px-0">
                        <v-date-picker @click="changeCurrentDestinationIndex(destination)" :masks="masks" :min-date="destination !== 0 ? minDates[destination - 1].min_date : minDates[0].min_date" v-model="allOrderData[destination].from_date" :attributes="attrs">
                            <template v-slot="{ inputValue, inputEvents }">
                                <label class="text-black pl-2">{{ $t('dates.start_date') }}</label>
                                <input
                                    class="w-full px-3 py-2 text-xl border-sky-300 border rounded-xl focus:outline-none focus:border-sky-300"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    :placeholder="$t('order.select_start')"
                                />
                            </template>
                        </v-date-picker>
                    </div>

                    <div class="purchase-rent-period__calendar w-full m-auto md:w-full md:px-0">
                        <v-date-picker @click="changeCurrentDestinationIndex(destination)" :masks="masks" :min-date="destination ? minDates[destination - 1].min_date : minDates[0].min_date" v-model="allOrderData[destination].to_date" :attributes="attrs">
                            <template v-slot="{ inputValue, inputEvents }">
                                <label class="text-black pl-2">{{ $t('dates.end_date') }}</label>
                                <input
                                    class="w-full px-3 py-2 text-xl border-sky-300 border rounded-xl focus:outline-none focus:border-sky-300"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    :placeholder="$t('order.select_end')"
                                />
                            </template>
                        </v-date-picker>
                    </div>
                </div>
                <img @click="deleteDestination(destination)" class="hidden md:block w-3 h-3 mb-0 md:mb-5" src="@/assets/png/x-mark.png" alt="">
            </div>
        </template>
        <Button
            class="sm:mt-3"
            text="Add destination"
            color="hover"
            @click="addDestination"
        />
    </div>
</template>

<script>
import InputSelect from "@/components/ui/inputs/InputSelect";
import {mapGetters} from "vuex";
import moment from "moment";
import _ from "lodash";
import Spinner from "@/components/ui/Spinner";
import PopularCountries from "@/views/purchase/_modules/popular-countries/PopularCountries";
import {useI18n} from "vue-i18n/dist/vue-i18n";
import Button from "@/components/ui/Button";

export default {
    name: "PurchaseRentCountry",

    components: {
        Button,
        PopularCountries,
        Spinner,
        InputSelect
    },
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },
    data() {
        return {
            isBoxActive: false,
            isClicked: false,
            masks: {
                input: 'MM/DD/YYYY',
            },
            currentFlag: null,
            attrs: [
                {
                    highlight: {
                        start: { fillMode: 'light' },
                        end: { fillMode: 'light' },
                    },

                },
            ],
            loading: {
                popularCountries: false,
                continents: false,
                countries: false,
            },
            selectContinentKeywords: '',
            selectCountryKeywords: '',

            selectedContinent: null,

            selectedCountry: null,
            countryOptions: [],
            staticLocations: [
                {name: 'Tom Bradley International Airport LAX', value: 1},
                {name: 'Sakura Rent A Car (LAX)', value: 2},
                {name: 'Elephant Tour (Downtown LA)', value: 3},
                {name: 'Postal Annex 44', value: 4},
                {name: 'Pacific Mail', value: 5},
                {name: 'AIM Mail Center 58', value: 6},
                {name: 'UX Parcel', value: 7},
            ],
        }
    },

    computed: {
        ...mapGetters({
            orderData: 'getOrderData',
            continents: 'getContinents',
            countries: 'getCountries',
            popularCountries: 'getPopularCountries',
            selectedLanguage: 'getSelectedLanguage',
            allOrderData: 'getAllOrderData',
            destinationIndex: 'getDestinationIndex',
            countryPackages: 'getCountryPackages',
            destinationsCount: 'getDestinationCount',
            minDates: 'getMinDates'
        }),
    },
    methods: {
        deleteDestination(currentDestination) {
            this.$store.dispatch('setDestinationCount', 'minus')
            this.minDates.splice(currentDestination, 1)
            this.allOrderData.splice(currentDestination, 1)
        },
        changeCurrentDestinationIndex(currentDestination) {
            this.$store.dispatch('setDestinationIndex', currentDestination)
            let orderData = _.cloneDeep(this.allOrderData[currentDestination])
            orderData.from_date = this.allOrderData[currentDestination].from_date
            orderData.to_date = this.allOrderData[currentDestination].to_date
            this.$store.dispatch('setOrderData', orderData);
        },
        addDestination() {
            if(!this.orderData.country_name || !this.orderData.to_date || !this.orderData.from_date) {
                this.$toast.warning('Please choose your first destination', {
                    position: "bottom-left",
                });
            } else {
                this.$store.dispatch('setDestinationCount', 'plus')
                const newData = {
                    flag: null,
                    // Period
                    from_date: null,
                    to_date: null,
                    // Continent
                    continent_id: null,
                    continent_api_id: null,
                    continent_name: null,
                    // Country
                    country_id: null,
                    country_api_id: null,
                    country_name: null,
                    country_name_jp: null,
                    // Country packages
                    country_package_id: null,
                    country_package_name: null,
                    country_package_price: 0,
                    // offering_id: null,
                    offering_code: null,
                    // Customer
                    customer_id: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    // Address
                    address: null,
                    city: null,
                    country: null,
                    state: null,
                    zip_code: null,
                    //return by mail
                    return_address: null,
                    return_city: null,
                    return_country: null,
                    return_state: null,
                    return_zip_code: null,
                    return_price: 15,
                    return_by_mail: false,
                    // Shipment
                    shipping_type_id: null,
                    shipping_type_api_id: null,
                    shipping_type_name: null,
                    shipping_type_description: null,
                    shipping_type_charges: 0,
                    shipping_return_price: 0,
                    is_delivery: true,
                    return_date: null,
                    // Pickup
                    pickup_location_id: null,
                    pickup_location_api_id: null,
                    pickup_location_name: null,
                    pickup_dt_tm: null,
                    pickup_time: null,
                    pickup_time_note: null,
                    pickup_or_delivery: null,
                    pickup_type: "Delivery",
                    // Coupon
                    coupon_id: null,
                    coupon_api_id: null,
                    coupon_code: null,
                    coupon_type: null,
                    coupon_country_id: null,
                    coupon_amount: 0,
                    coupon_total_amount: 0,
                    // Insurance
                    insurance_status: false,
                    insurance_amount: 2,
                    insurance_total_price: 0.0,

                    order_id: '',
                    number_of_days: 0,
                    number_of_devices: 1,
                    plan_amount: 0,
                    total_amount: 0,
                    gift_card_id: null
                };
                if (this.allOrderData.length > 1) {
                    const fromDate = new Date(this.allOrderData[this.destinationIndex].to_date);
                    fromDate.setDate(fromDate.getDate() + 1);
                    newData.from_date = fromDate.toISOString();
                } else {
                    const fromDate = new Date(this.allOrderData[0].to_date);
                    fromDate.setDate(fromDate.getDate() + 1);
                    newData.from_date = fromDate.toISOString();
                }
                if (this.allOrderData.length > 1) {
                    const minDateForm = {
                        min_date: this.allOrderData[this.destinationIndex].to_date
                    }
                    this.$store.dispatch('setMinDates', minDateForm)
                } else {
                    const minDateForm = {
                        min_date: this.allOrderData[0].to_date
                    }
                    this.$store.dispatch('setMinDates', minDateForm)
                }


                this.$store.commit('addOrderData', newData);
            }
        },
        loadContinents() {
            this.$axios.get(`/continents/search`, {
                params: {
                    limit: 10,
                    keywords: this.selectContinentKeywords
                }
            })
                .then(res => {
                    this.$store.dispatch('setContinents', res.data)
                    this.continents.forEach(continent => {
                        if(this.selectedLanguage === 'jp' && continent.name_jp) {
                            continent.name = continent.name_jp
                        }
                    })
                });
        },

        loadCountries() {
            let url = `/countries/search`

            this.$axios.get(url, {
                params: {
                    keywords: this.selectCountryKeywords
                }
            })
                .then(res => {
                    this.$store.dispatch('setCountries', res.data)
                    this.countries.forEach(country => {
                        if(this.selectedLanguage === 'jp' && country.name_jp) {
                            country.name = country.name_jp
                        }
                    })
                });
        },

        inputSelectCountry: _.debounce(function (e) {
            this.selectCountryKeywords = e && e.target.value ? e.target.value : '';
        }, 300),


        selectCountry(data) {
            this.$store.dispatch('setDestinationIndex', 0)
            this.$store.dispatch('clearOrderData', 'package');
            this.$store.dispatch('clearOrderData', 'coupon');
            this.$store.dispatch('clearOrderData', 'insurance');
            this.$store.dispatch('clearOrderData', 'other');
            let orderData = _.cloneDeep(this.orderData);
            orderData.country_id = data.id;
            orderData.country_api_id = data.api_country_id;
            orderData.country_name = data.name;
            orderData.country_name_jp = data.name_jp;
            orderData.continent_id = data.continent_id;
            orderData.continent_api_id = data.api_continent_id;
            orderData.continent_name = data.continent_name;
            orderData.flag = data.img;
            this.$store.dispatch('setOrderData', orderData);
            this.loadCountryPackages(data.id);
        },
        selectOtherCountries(data, destination) {
            this.$store.dispatch('setDestinationIndex', destination)
            let orderData = _.cloneDeep(this.allOrderData[destination]);
            if(this.destinationsCount !== 0) {
                orderData.country_id = data.id;
                orderData.country_api_id = data.api_country_id;
                orderData.country_name = data.name;
                orderData.country_name_jp = data.name_jp;
                orderData.continent_id = data.continent_id;
                orderData.continent_api_id = data.api_continent_id;
                orderData.continent_name = data.continent_name;
                orderData.flag = data.img;
                this.$store.dispatch('setOrderData', orderData)
                this.loadCountryPackages(data.id);
            }
        },

        loadCountryPackages(countryId) {
            this.$axios.get(`/countries/${countryId}/get-country-packages`)
                .then(res => {
                    this.$store.dispatch('setCountryPackages', res.data);
                });
        },
    }
}
</script>

<style scoped>

</style>