<template>
    <app-layout>
        <div class="w-full bg-white">
            <div
                class="py-32 px-4 flex justify-center gap-8 xl:gap-0 items-center container mx-auto max-w-5xl flex-wrap">
                <div>
                    <h1 class="text-5xl font-semibold mb-8 text-center text-sky-300">
                        Thank You!
                    </h1>

                    <div class="mb-8 text-center">
                        <h1 class="text-2xl font-semibold text-orange-500">
                            Your purchase was successful
                        </h1>
                        <p class="font-semibold  text-orange-500">You will receive a message with a gift card number to
                            your email address</p>
                    </div>
                    <h2 class="text-2xl font-bold text-orange-500 text-center mb-8">
                        Want to send it to someone else?
                    </h2>
                    <input-text
                        color="orange"
                        placeholder="Your name"
                        v-model="form.name"
                    />
                    <textarea
                        v-model="form.message"
                        placeholder="Message"
                        class="h-40 mb-6 border-orange-500 outline-orange-500 block w-full rounded-xl border focus:outline-offset-3 focus:outline-4 pl-4 text-base p-2"
                    />
                    <input-text
                        v-model="form.resend_email"
                        color="orange"
                        placeholder="Enter email address"
                        type="email"
                    />
                    <div class="flex sm:gap-6 sm:flex-nowrap flex-wrap">
                        <button
                            class="mb-5 sm:mb-10 btn pointer capitalize w-full text-center text-xl font-semibold justify-center flex items-center gap-4 main-bg m-auto text-white border-none py-4 px-10 rounded-3xl"
                            @click="resendTo"
                        >
                            {{ loading ? 'Sending...' : 'Send' }}
                        </button>
                        <button
                            class="mb-5 sm:mb-10 btn pointer w-full capitalize text-center justify-center text-xl font-semibold flex items-center gap-4 main-bg m-auto text-white border-none py-4 px-10 rounded-3xl"
                            @click="redirectAndClear"
                        >
                            Go to home page
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import InputText from "@/components/ui/inputs/InputText";

export default {
    name: "GiftCheckoutResult",
    components: {InputText, AppLayout},
    data() {
        return {
            form: {
                resend_email: '',
                name: '',
                message: '',
                amount: '',
                quantity: '',
                code: '',
                status: '',
                user_id: '',
                email: '',
                customer_id: '',
                payment_method_id: ''
            },
            loading: false
        }
    },
    methods: {
        redirectAndClear() {
            this.$router.push({name: 'Home'})
        },
        resendTo() {
            this.loading = true
            this.form.amount = this.$route.params.amount;
            this.form.quantity = this.$route.params.quantity;
            this.form.code = this.$route.params.code;
            this.form.status = this.$route.params.status;
            this.form.user_id = this.$route.params.user_id;
            this.form.email = this.$route.params.email;
            this.form.customer_id = this.$route.params.customer_id;
            this.form.payment_method_id = this.$route.params.payment_method_id;
            this.$axios.post(`gift-cards/resend`, this.form)
                .then(res => {
                    this.loading = false
                    this.$router.push({name: 'Home'})
                })
                .catch(e => {
                    this.loading = false
                })
        }
    }

}
</script>

<style scoped>

</style>