<template>
    <div class="mb-6 w-full">
        <label v-if="label" :class="labelStyles()">{{ label }}</label>
        <input
            :type="type"
            :placeholder="placeholder"
            :class="inputStyles()"
            :disabled="disable"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            @blur="$emit('blur')"
        />
        <p class="mt-2 text-sm text-red-600" v-if="errorMessage">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script>
export default {
    name: "InputText",
    props: {
        size: {
            type: String,
            default() {
                return "base";
            },
        },
        errorMessage: {
            type: String,
            default() {
                return "";
            },
        },
        label: {
            type: String,
            default() {
                return "";
            },
        },
        disable: {
            type: Boolean,
            default() {
                return false;
            },
        },
        modelValue: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        color: {
            type: String,
            default() {
                return "blue";
            },
        },
    },
    methods: {
        inputStyles() {
            let style = `input-select block w-full rounded-xl border
        focus:outline-offset-3 focus:outline-4 border-2 pl-4`;

            if (this.size === 'base') {
                style += ` text-base p-2`;
            }

            if (this.size === 'sm') {
                style += ` text-sm p-2`;
            }

            if (this.size === 'lg') {
                style += ` text-lg p-2`
            }

            if (this.size === 'coupon') {
              style += ` text-lg p-2 uppercase`
            }

            if (this.color === 'blue') {
                style += ` `
            }

            if (this.color === 'orange') {
                style += ` border-orange-500 focus:ring-orange-500 focus:border-orange-500 outline-orange-500`
            }

            if (this.errorMessage) {
                style += ` bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 outline-red-500`
            } else {
                style += ` bg-white border-sky-300 text-black outline-blue-300 focus:bg-white`
            }

            return style;
        },

        labelStyles() {
            let labelStyle = `block mb-2 text-sm `;
            if (this.errorMessage) {
                labelStyle += ` text-red-600 `;
            }
            return labelStyle;
        },
    },
};
</script>

<style scoped></style>
