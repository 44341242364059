<template>
  <form>
    <div class="mb-6">
        <input
            type="text"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            :placeholder="placeholder"
            required
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        >
        <p class="mt-2 text-sm text-red-600" v-if="errorMessage">
            {{ errorMessage }}
        </p>
    </div>
  </form>
</template>

<script>
export default {
    name: "TextInput",
    props: {
        placeholder: {
            type: String,
            default() {
                return 'First name'
            }
        },
        modelValue: {
            type: String,
            default: null,
        },
        errorMessage: {
            type: String,
            default() {
                return "";
            },
        },
    }
}
</script>

<style lang="scss">

</style>