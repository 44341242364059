<template>
    <vue-final-modal
        :name="name"
        :value="true"
        v-model="show"
    >
        <slot></slot>
    </vue-final-modal>
</template>

<script>
import { VueFinalModal, ModalsContainer } from 'vue-final-modal'

export default {
    name: 'Modal',

    components: {
        VueFinalModal,
        ModalsContainer
    },

    props: {
        name: {
            type: String,
            default() {
                return null
            }
        },
        show: {
            type: Boolean,
            default() {
                return false
            }
        }
    },

    data() {
        return {
        }
    },

    created() {
        this.emitter.on(`modal::show::${this.name}`, this.openModal);
        this.emitter.on(`modal::hide::${this.name}`, this.hideModal);
    },

    beforeUnmount() {
        this.emitter.off(`modal::show::${this.name}`, this.openModal);
        this.emitter.off(`modal::hide::${this.name}`, this.hideModal);
    },

    methods: {
        openModal() {
            this.show = true;
        },

        hideModal() {
            this.show = false;
        }
    }
}
</script>

<style scoped>

</style>