<template>
    <div
        v-if="showCheckoutProductInfo"
        class="border-2 border-sky-300 p-6 rounded-xl bg-white sm:mx-0"
    >

        <div class="flex mb-2">
            <div class="pr-6">
                <img
                    class="w-40"
                    src="@/assets/png/pro-2.png"
                    alt="the best mobile hotspot for travel"
                >
            </div>

            <div class="w-full">
                <h1 class="font-semibold">
                    {{ $t('order.rental') }}
                </h1>

                <template v-for="order in allOrderData">
                    <p>{{ order.country_name }}</p>
                    <p class="">
                        {{ order.number_of_days }}
                        {{ $t('order.days_period') }} {{ moment(order.from_date).format('MM/DD/YYYY') }}
                        {{ order.to_date ? '- ' + moment(order.to_date).format('MM/DD/YYYY') : '' }}
                    </p>
                </template>
            </div>
        </div>

        <hr class="w-full blue-bg h-0.5 mb-2"/>

        <ul>
            <li class="flex justify-between items-center mb-2">
              <p>{{ $t('order.device_quantity') }}</p>
              <span>{{ orderData.number_of_devices }}</span>
            </li>
            <li v-for="(country, i) in allOrderData" class="flex justify-between items-center mb-2">
                <p>{{ country.country_name }}</p>
                <span>${{ orderPlanAmount[i] }}</span>
            </li>
            <li class="flex justify-between items-center mb-2">
                <p>{{ $t('order.plan_total') }}</p>
                <span>${{ orderPlanAmount.reduce((total, amount) => total + amount, 0) }}</span>
            </li>
            <li class="flex justify-between items-center mb-2">
                <p>{{ $t('order.discount_info') }} <span class="text-sky-300">{{ orderData.coupon_id ? orderData.coupon_code.toUpperCase() : '' }}</span></p>
                <span>${{ getDiscount % 2 === 0 ? getDiscount : getDiscount.toFixed(2) }}</span>
            </li>
            <li class="flex justify-between items-center mb-2">
                <p>Gift Card Code</p>
                <span>${{ giftCardDiscount % 2 === 0 ? giftCardDiscount : giftCardDiscount.toFixed(2) }}</span>
            </li>
            <li class="flex justify-between items-center mb-2">
                <p>{{ $t('order.insurance') }}</p>
                <span>${{ insuranceAmount.reduce((total, amount) => total + amount, 0) }}</span>
            </li>

            <li class="flex justify-between items-center mb-2">
                <p>Subtotal</p>
                <span>${{ getSubtotal % 2 === 0 ? getSubtotal : getSubtotal.toFixed(2) }}</span>
            </li>

            <li class="flex justify-between items-center mb-2">
                <p>{{ $t('order.shipping') }}</p>
                <span>${{ shipmentDeliveryPrice.reduce((total, amount) => total + amount, 0) }}</span>
            </li>
            <li class="flex justify-between items-center mb-2">
                <p>{{ $t('order.tax') }}</p>
                <span>$0.00</span>
            </li>
            <li class="flex justify-between mb-2 items-start">
                <p>Shipping method:</p>
                <div class="flex flex-col items-end">
                    <span>{{ orderData.pickup_type }}</span>
                    <p v-if="orderData.pickup_type === 'Pickup'">{{ orderData.pickup_location_name }}</p>
                </div>
            </li>
        </ul>

        <hr class="w-full blue-bg h-0.5 mb-2"/>

        <div class="flex justify-between items-center mb-8">
            <p class="text-sky-300 text-3xl font-bold">{{ $t('order.total') }}</p>
            <span class="text-sky-300 text-3xl font-bold">${{ deductedAmount > 0 ? 0 : orderTotalAmount % 2 === 0 ? orderTotalAmount : orderTotalAmount.toFixed(2) }}</span>
        </div>

        <template
            v-if="returnDate && orderData.country_package_id"
        >
            <p class="text-red-500">{{ $t('order.return') }} {{ moment(returnDate).format('MM/DD/YYYY') }}</p>

            <span
                class="text-red-500"
                v-if="selectedLang === 'en'"
            >
                * Late fees begin accumulating after
                {{ moment(returnDate).format('MM/DD/YYYY') }}, $10 per day.
                <p>
                    {{ orderData.pickup_or_delivery }}
                </p>
            </span>
            <span
                class="text-red-500"
                v-if="selectedLang === 'jp'"
            >
                *返却期限を過ぎると1日あたり$10の延滞料金がかかります。
            </span>
        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from 'moment'

import _ from "lodash";
import {useI18n} from "vue-i18n/dist/vue-i18n";

export default {
    name: "PurchaseCheckoutProductInfo",
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },
    props: {
        showIcon: {
            type: Boolean,
            default() {
                return true
            }
        }
    },

    data() {
        return {
            numToAdd: 5
        }
    },

    created: function () {
        this.moment = moment;
    },

    computed: {
        ...mapGetters({
            orderData: 'getOrderData',
            allOrderData: 'getAllOrderData',
            orderPlanAmount: 'getOrderPlanAmount',
            orderTotalAmount: 'getOrderTotalAmountForClient',
            couponDiscountAmount: 'getCouponDiscountAmount',
            insuranceAmount: 'getInsuranceAmount',
            shipmentDeliveryPrice: 'getShipmentDeliveryPrice',
            selectedLang: 'getSelectedLanguage',
            deductedAmount: 'getDeductedAmount',
            giftCardDiscount: 'getGiftCardDiscount',
        }),

        showCheckoutProductInfo() {
            if (this.orderData
                && this.orderData.country_id
            ) {
                return true;
            }

            return false;
        },
        getSubtotal() {
            return this.orderPlanAmount.reduce((total, amount) => total + amount, 0) - (this.couponDiscountAmount.reduce((total, amount) => total + amount, 0) + this.insuranceAmount.reduce((total, amount) => total + amount, 0))
        },
        getDiscount() {
            return this.couponDiscountAmount.reduce((total, amount) => total + amount, 0)
        },
        returnDate() {
            let orderData = _.cloneDeep(this.allOrderData[this.allOrderData.length - 1]);
            if(orderData && orderData.to_date && orderData.from_date) {
                let endDate = orderData.to_date ? orderData.to_date : orderData.from_date;

                if (endDate) {
                    for (let i = 1; i <= this.numToAdd; i++) {
                        endDate.setDate(endDate.getDate() + 1);
                        if (endDate.getDay() === 6) {
                            endDate.setDate(endDate.getDate() + 2);
                        } else if (endDate.getDay() === 0) {
                            endDate.setDate(endDate.getDate() + 1);
                        }
                    }
                }

                return endDate;
            }
        },
    },
    watch: {
        returnDate() {
            this.orderData.return_date = moment(this.returnDate).format('MM/DD/YYYY')
        }
    }
}
</script>

<style scoped>

</style>