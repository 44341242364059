<template>
    <div class="bg-white p-6 rounded-xl container mx-auto">
        <div @click="openAccordion" class="flex justify-between items-center cursor-pointer">
            <div class="flex gap-6 items-center">
                <p class="blue-text font-bold text-4xl">{{ accord.id }}</p>
                <h2 class="text-3xl font-bold">{{ accord.title }}</h2>
            </div>
            <div class="plus-container pr-6">
                <span class="first-span"></span>
                <span class="second-span" :class="accordionOpened ? 'hidden' : ''"></span>
            </div>
        </div>
        <div v-if="accordionOpened" class="px-12 text-2xl mt-6">
            <p>{{ accord.body }}</p>
            <p v-for="note in accord.notes">
                {{ note }}
            </p>
            <div :class="accord.tables ? 'overflow-scroll' : ''">
                <table class="border-collapse border border-slate-500 overflow-x-auto">
                    <thead>
                    <tr v-if="selectedLang === 'en' && accord.tables">
                        <th class="p-2">Coverage Application</th>
                        <th class="p-2">Normal Plan (300MB/day)</th>
                        <th class="p-2">Large capacity plan (600MB/day)</th>
                        <th class="p-2">Super large capacity plan (1.1GB/day)</th>
                    </tr>
                    <tr v-if="selectedLang === 'jp' && accord.tables">
                        <th class="p-2">対象アプリ</th>
                        <th class="p-2">通常プラン （300MB/日）</th>
                        <th class="p-2">大容量プラン
                            （600MB/日）</th>
                        <th class="p-2">超大容量プラン
                            （1.1GB/日）</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="plans in accord.tables">
                        <td class="border border-slate-500 p-2" v-for="plan in plans.plans">{{ plan }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Accordion",
    computed: {
        ...mapGetters({
            selectedLang: 'getSelectedLanguage'
        }),
    },
    data() {
        return {
            accordionOpened: false,
        }
    },
    props: {
        accord: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    methods: {
        openAccordion() {
            this.accordionOpened = !this.accordionOpened
        }
    }
}
</script>

<style lang="scss">
.plus-container {
    position: relative;

    .first-span {
        &:before {
            content: '';
            display: block;
            width: 34px;
            height: 3px;
            background: gray;
            border-radius: 5px;
            position: absolute;
            left: -16px;
        }
    }

    .second-span {
        &:after {
            content: '';
            display: block;
            width: 3px;
            height: 34px;
            background: gray;
            border-radius: 5px;
            position: absolute;
            top: -16px;
            left: 0;
        }
    }
}
</style>