<template>
    <div class='flex flex-col items-center relative'>
        <h5 class=" blue-text font-semibold">
            Start Date
        </h5>
        <p
            class="font-normal blue-text cursor-pointer"
            @click="toggleSelectDate"
        >
            {{ date ? date : 'Select date'}}
        </p>
        <div
            v-show="purchasingData.startDate.open"
            class="calendar absolute left-0 sm:left-auto"
        >
            <v-date-picker
                v-model="date"
                :model-config="dateModelConfig"
            />
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
    name: "PurchasingBarStartDate",

    data() {
        return {
            date: null,
            dateModelConfig: {
                type: 'string',
                mask: 'MM/DD/YYYY',
            }
        }
    },

    computed: {
        ...mapGetters({
            purchasingData: 'getPurchasingData',
        })
    },

    watch: {
        date(value) {
            if (value) {
                let purchasingData = _.cloneDeep(this.purchasingData);
                purchasingData.startDate.value = value;

                this.$store.dispatch('setPurchasingDestination', purchasingData);
            }

            this.toggleSelectDate();
        }
    },

    methods:{
        toggleSelectDate() {
            let purchasingData = _.cloneDeep(this.purchasingData);
            purchasingData.destination.open = false;
            purchasingData.endDate.open = false;
            purchasingData.startDate.open = !purchasingData.startDate.open;

            this.$store.dispatch('setPurchasingDestination', purchasingData);
        },
    }
}
</script>

<style lang="scss">
.payment-bar {
    &__start-date {
        max-width: 150px;

        .calendar {
            top: 4.5rem;
        }
    }
}
</style>