<template>
<div class="my-20">
  <div class="container mx-auto py-2 px-4">
    <div class="about flex xl:justify-between flex-wrap justify-center gap-16 xl:gap-0">
      <div class="max-w-sm">
        <h2 class="text-3xl mb-6 text-center xl:text-left">{{ $t('about.title') }}</h2>
        <p class="mb-0 xl:mb-8 text-center xl:text-left">{{ $t('about.description') }}</p>
        <Button
          :text="$t('buttons.seeIfItWorks')"
          color="blue"
          size="lg"
          @click="$router.push('purchase-rent')"
          class="hidden xl:flex"
        >
          <div>
            <img loading="lazy" class="" src="@/assets/svg/round-arrow-right.svg" alt="">
          </div>
        </Button>
      </div>
      <div class="flex justify-center flex-wrap sm:flex-nowrap sm:justify-between sm:gap-10 gap-10">
        <Card
          :text="$t('about.cards.anywhere')"
        >
          <div>
            <img loading="lazy" class="w-14 sm:w-24" src="@/assets/png/global-wifi.png" alt="">
          </div>
        </Card>
        <Card
            v-smooth-scroll
            :text="$t('about.cards.pickup')"
            href="#location"
            class="cursor-pointer"
        >
          <div>
            <img loading="lazy" class="w-14 sm:w-24" src="@/assets/png/airplane.png" alt="">
          </div>
        </Card>
        <Card
            :text="$t('about.cards.cost')"
        >
          <div>
            <img class="w-14 sm:w-24" src="@/assets/png/cost-effectiveness-icon.png" alt="">
          </div>
        </Card>
      </div>
        <Button
            :text="$t('buttons.seeIfItWorks')"
            color="blue"
            size="lg"
            @click="$router.push('purchase-rent')"
            class="flex xl:hidden"
        >
            <div>
                <img loading="lazy" class="" src="@/assets/svg/round-arrow-right.svg" alt="">
            </div>
        </Button>
    </div>
  </div>
</div>
</template>

<script>
import Card from "../../components/ui/Card";
import Button from "../../components/ui/Button";
import {useI18n} from "vue-i18n/dist/vue-i18n";
export default {
  name: "About.vue",
  components: {Button, Card},
  methods: {
    onScroll(e) {
      this.windowTop = window.top.scrollY
    }
  },
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    }
}
</script>

<style lang="scss">
.about {
  h2 {
    font-weight: 700;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
}

</style>