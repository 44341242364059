<template>
<div class="bg-black mt-16 pb-16 xl:pb-0">
  <div class="container mx-auto">
    <div class="footer flex justify-center gap-6 md:gap-0 md:justify-between p-6 items-center flex-wrap md:flex-nowrap">
      <div>
        <p class="text-white text-center md:text-left">COPYRIGHT © 2024. SKYGO WIFI. ALL RIGHTS RESERVED.</p>
      </div>
      <div class="flex gap-6 flex-wrap">
        <a href="https://twitter.com/SkyGoWifi" target="_blank" class="icon-bg rounded-full h-12 w-12 flex items-center justify-center">
          <img class="w-4" src="@/assets/svg/twitter-brands.svg" alt="">
        </a>
        <a href="https://www.facebook.com/SkyGoWifi/" target="_blank" class="icon-bg rounded-full h-12 w-12 flex items-center justify-center">
          <img class="w-4" src="@/assets/svg/facebook-f-brands.svg" alt="">
        </a>
        <a href="https://www.instagram.com/skygowifii/" target="_blank" class="icon-bg rounded-full h-12 w-12 flex items-center justify-center">
          <img class="w-4" src="@/assets/svg/instagram-brands.svg" alt="">
        </a>
        <a href="https://www.youtube.com/channel/UC1bDhSsOSLAqDyyTVEUxaCw" target="_blank" class="icon-bg rounded-full h-12 w-12 flex items-center justify-center">
          <img class="w-4" src="@/assets/svg/youtube-brands.svg" alt="">
        </a>
        <a href="https://www.tiktok.com/@skygowifi" target="_blank" class="icon-bg rounded-full h-12 w-12 flex items-center justify-center">
          <img class="w-4" src="@/assets/svg/icons8-tiktok.svg" alt="">
        </a>
      </div>
    </div>

  </div>

</div>
</template>

<script>
import Contacts from "../views/home-pages/Contacts";
export default {
  name: "Footer",
  components: {Contacts},
}
</script>

<style lang="scss">
.footer {
  p {
    font-family: 'Montserrat', sans-serif;
  }
}
</style>