
export default {
    state: () => ({
        authResultType: 'home'
    }),

    mutations: {
        setAuthResultType(state, payload) {
            state.authResultType = payload;
        }
    },

    actions: {
        setAuthResultType({commit}, payload) {
            commit('setAuthResultType', payload);
        }
    },

    getters: {
        getAuthResultType(state) {
            return state.authResultType;
        }
    },
}