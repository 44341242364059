<template>
    <div class="lax my-40 relative">
        <Modal :show="isModalActive">
            <div>
                <div v-if="status === 1"  class="relative mx-auto mt-12 max-w-5xl">
                    <button @click="closeModal" class="absolute top-0 right-2 text-white text-4xl">X</button>
                    <img loading="lazy" class="mx-auto" src="@/assets/png/lax-img.jpg" alt="">
                </div>
                <div v-if="status === 2"  class="relative mx-auto mt-12 max-w-5xl">
                    <button @click="closeModal" class="absolute top-0 right-2 text-white text-4xl">X</button>
                    <img loading="lazy" class="mx-auto" src="@/assets/png/sfo-img.jpg" alt="">
                </div>
            </div>
        </Modal>
        <div class="container mx-auto">
            <div class="py-14 sm:py-28">
                <div class="flex justify-center">
                    <p class="text-4xl sm:text-5xl font-bold text-white mb-7 sm:mb-14 bg-black bg-opacity-50 inline-block p-2 text-center">{{ $t('lax.title') }}</p>
                </div>
                <div class="flex justify-center xl:justify-between items-start flex-wrap gap-7 xl:gap-0">
                    <div>
                        <div class="mx-auto">
                            <p class="text-white text-xl sm:text-4xl text-center font-semibold bg-black bg-opacity-50 p-2">
                                LAX(Terminal B)<br> ICE Currency Exchange Booth<br> Departure & Arrival Floors
                            </p>
                        </div>
                        <p @click="setModalActive(1)" class="text-white underline cursor-pointer">Click here to see SFO /LAX/ location photo</p>
                        <div class="location">
                            <Map />
                        </div>
                    </div>

                    <div>
                        <div class="mx-auto">
                            <p class="text-white text-xl sm:text-4xl text-center font-semibold bg-black bg-opacity-50 p-2">SFO<br> International Arrivals <br> Near Exit A</p>
                        </div>
                        <p @click="setModalActive(2)" class="text-white underline cursor-pointer">Click here to see SFO /LAX/ location photo</p>
                        <div class="location">
                            <Map
                                map="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12640.480237691077!2d-122.37536681783817!3d37.62286360560446!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f778c55555555%3A0xa4f25c571acded3f!2sSan%20Francisco%20International%20Airport!5e0!3m2!1sen!2skg!4v1689145146863!5m2!1sen!2skg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img loading="lazy" class="lax-bg" src="../../assets/png/lax-image.webp" alt="">
        <div class="banner-opacity"></div>
    </div>
</template>

<script>
import Map from '@/components/ui/Map'
import {useI18n} from "vue-i18n/dist/vue-i18n";
import Modal from "@/components/ui/modal/Modal";
import Button from "@/components/ui/Button";

export default {
    name: "Lax",
    components: {Button, Modal, Map},
    data() {
        return {
            isModalActive: false,
            status: null
        }
    },
    methods: {
        setModalActive(id) {
            this.status = id
            this.isModalActive = !this.isModalActive
        },
        closeModal() {
            this.isModalActive = false
        }
    },
    setup() {
        const {t} = useI18n({
            useScope: 'global'
        })

        return {t}
    }
}
</script>

<style lang="scss">
.lax {
    .banner-opacity {
        z-index: -1;
        background: #35b8f5;
        opacity: 0.3;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .lax-bg {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
    }
    .lax-japan-bg {
        background-image: url("../../assets/png/japan-plain.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;

        &:before {
            content: "";
            background: #35b8f5;
            opacity: 0.3;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    .location {
        iframe {
            width: 500px;
            height: 350px;
            border-radius: 8px;
            @media all and (max-width: 640px) {
                width: 400px;
                height: 250px;
            }
            @media all and (max-width: 440px) {
                width: 300px;
            }
        }
    }
}

</style>