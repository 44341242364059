<template>
<div class="steps-card flex justify-center sm:justify-between sm:flex-nowrap flex-wrap max-w-3xl mx-auto mb-24 text-center sm:text-left rounded-r-lg">
  <slot class="steps-card__bg" name="image"></slot>
  <div class="steps-info">
    <h3 class="mb-4 text-xl blue-text">{{ step }}</h3>
    <h4 class="mb-4 text-2xl">{{ title }}</h4>
    <p class="text-lg gray-text">{{ desc }}</p>
  </div>
  <div class="steps-image">
    <slot name="second-image"></slot>
  </div>
</div>
</template>

<script>
export default {
  name: "StepsCard",
  props: {
    step: {
      type: String,
      default() {
        return 'STEP1'
      }
    },
    title: {
      type: String,
      default() {
        return 'Application & Payment'
      }
    },
    desc: {
      type: String,
      default() {
        return 'The Payments Can Be Made Via Credit Card Or Different Payment Methods.'
      }
    },
  }
}
</script>

<style lang="scss">
.steps-card{
  position: relative;

  &__bg {
    position: absolute;
    z-index: -1;
    left: calc(378px * (-0.4));
  }
  .steps-info {
    max-width: 550px;
    width: 100%;
    h3 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
    }
    h4 {
      font-weight: 700;
    }
    p {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;

    }
  }
}
</style>