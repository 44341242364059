<template>
    <app-layout>
        <iframe
            id="JotFormIFrame-240096530745153"
            title="Japan SIM/eSIM Purchase form"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            src="https://form.jotform.com/240096530745153"
            frameborder="0"
            style="min-width: 100%; max-width:100%; border:none; height: 900px"
        >
        </iframe>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
export default {
    name: "Corporate",
    components: {AppLayout}
}
</script>

<style scoped>

</style>