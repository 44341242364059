<template>
    <div class="cursor-pointer" @click="$router.push(`/blog/${post.id}`)">
        <div class="mb-2">
            <slot></slot>
        </div>
        <h2 class="mb-2 text-sky-300 underline text-2xl font-semibold">{{ post.title }}</h2>
        <p class="mb-6">{{ post.desc }}</p>
        <p class="font-semibold">Read post</p>
    </div>
</template>

<script>
export default {
    name: 'BlogPost',
    props: {
        post: {
            default() {
                return null
            }
        },
    }
}
</script>

<style scoped>

</style>