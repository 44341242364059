<template>
    <app-layout>
        <Media />
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import Media from "@/views/home-pages/Media";
export default {
    name: "Tutorial",
    components: {Media, AppLayout}
}
</script>

<style scoped>

</style>