<template>
    <nav class="relative" id="nav">
        <p class="main-bg text-white text-center uppercase">{{ announcement }}</p>
        <div class="container mx-auto">
            <div class="relative flex justify-between pb-20 px-5 pt-5 z-10">
                <router-link v-if="this.getSubdomain !== 'nta.' && this.getSubdomain !== 'sakura-' && this.getSubdomain !== 'elephanttour.' && this.getSubdomain !== 'gulliverusa.' && this.getSubdomain !== 'amnet.' && this.getSubdomain !== 'lighthouse.' && this.getSubdomain !== 'kddi.'" to="/" class="logo z-20 block md:hidden lg:block relative w-[200px]">
                    <img class="w-[111px]" src="@/assets/png/skygo-logo.png" alt="">
                </router-link>
                <router-link v-if="this.getSubdomain === 'nta.'" to="/" class="logo z-20 block md:hidden lg:block my-auto relative w-[200px]">
                    <img class="" src="@/assets/png/nta-logo-removebg-preview.png" alt="">
                </router-link>
                <router-link v-if="this.getSubdomain === 'sakura-'" to="/" class="logo z-20 block md:hidden lg:block my-auto relative w-[200px]">
                    <img src="@/assets/png/sakura_logo-removebg-preview.png" alt="">
                </router-link>
                <router-link v-if="this.getSubdomain === 'elephanttour.'" to="/" class="logo z-20 block md:hidden lg:block my-auto relative w-[200px]">
                    <img class="w-[111px]" src="@/assets/png/elephant-logo.svg" alt="">
                    <img class="absolute -z-10 -top-[85px] -left-10 w-full" src="@/assets/png/blue-cloud-logo.png" alt="">
                </router-link>
                <router-link v-if="this.getSubdomain === 'amnet.'" to="/" class="logo z-20 block md:hidden lg:block my-auto relative w-[200px]">
                    <img class="w-[111px]" src="@/assets/png/AMNET-LOGO-Official.svg" alt="">
                    <img class="absolute -z-10 -top-[80px] -left-10 w-full" src="@/assets/png/blue-cloud-logo.png" alt="">
                </router-link>
                <router-link v-if="this.getSubdomain === 'gulliverusa.'" to="/" class="logo z-20 block md:hidden lg:block my-auto relative w-[200px]">
                    <img class="w-[111px]" src="@/assets/png/NewGulliver.png" alt="">
                    <img class="absolute -z-10 -top-[80px] -left-10 w-full" src="@/assets/png/blue-cloud-logo.png" alt="">
                </router-link>
                <router-link v-if="this.getSubdomain === 'lighthouse.'" to="/" class="logo block md:hidden lg:block my-auto">
                    <img class="w-1/3 my-auto" src="@/assets/png/lighthouse-black.png" alt="">
                </router-link>
                <router-link v-if="this.getSubdomain === 'kddi.'" to="/" class="logo block md:hidden lg:block my-auto">
                    <img class="w-[130px] my-auto" src="@/assets/png/KDDI_logos.svg.png" alt="">
                </router-link>
                <div class="nav-items flex items-center gap-10">
                    <div class="md:flex justify-between items-center gap-4 hidden">
<!--                        <template v-if="!user || !user.id">-->
<!--                            <button-->
<!--                                class="btn pointer capitalize text-center flex items-center gap-4 main-bg  text-white  border-none py-3 px-9 rounded-3xl"-->
<!--                                @click="showLoginModal"-->
<!--                            >-->
<!--                                {{ $t('navbar.login') }}-->
<!--                            </button>-->
<!--                        </template>-->
<!--                        <template v-if="user && user.id">-->
<!--                            <button-->
<!--                                class="btn pointer capitalize text-center flex items-center gap-4  main-bg  text-white  border-none py-3 px-9 rounded-3xl"-->
<!--                                @click="logout"-->
<!--                            >-->
<!--                                Logout-->
<!--                            </button>-->
<!--                        </template>-->
                        <change-language-dropdown />
                    </div>
                </div>
                <div class="flex gap-6 lg:hidden block">
                    <change-language-dropdown class="md:hidden" />
                    <div class="space-y-2 block my-auto" @click="toggleBurger()">
                        <div class="w-8 h-1 rounded bg-white"></div>
                        <div class="w-8 h-1 rounded bg-white"></div>
                        <div class="w-8 h-1 rounded bg-white"></div>
                    </div>
                </div>
            </div>
        </div>
        <div

            class="fixed z-50 bg-white h-full top-0 w-full sm:w-1/2 flex flex-col justify-between p-4 transition-all lg:hidden"
            :class="burger ? 'left-0' : '-left-999'"
        >
            <ul class="flex gap-4 flex-col p-2 relative">
                <div class="my-auto md:hidden block absolute top-4 right-4" @click="toggleBurger()">
                    <img class="w-3 h-3" src="@/assets/png/x-mark.png" alt="">
                </div>
<!--                <li class="list-none flex justify-between">-->
<!--                    <router-link to="/plans" class="flex items-center no-underline gap-4 font-medium">-->
<!--                        <img src="@/assets/svg/wifi-icon.svg" alt="">-->
<!--                        Plans-->
<!--                    </router-link>-->
<!--                </li>-->
                <li class="list-none">
                    <router-link to="/" class="flex items-center no-underline gap-4 font-medium ml-4">
                        {{ $t("navbar_upd.homepage") }}
                    </router-link>
                </li>
                <li v-for="item in navbarEls" class="list-none">
                    {{ item.name }}
                    <router-link v-for="subItem in item.elements" :to="`${subItem.link}`" class="flex items-center no-underline gap-4 font-medium ml-4">
                        {{ subItem.title }}
                    </router-link>
                </li>
            </ul>
<!--            <div class="flex justify-between items-center gap-4 p-4">-->
<!--                <template v-if="!user || !user.id">-->
<!--                    &lt;!&ndash;            <router-link to="/login" class="btn pointer capitalize text-center flex items-center gap-4  main-bg  text-white  border-none py-2 px-9 rounded-3xl">&ndash;&gt;-->
<!--                    &lt;!&ndash;                Login&ndash;&gt;-->
<!--                    &lt;!&ndash;            </router-link>&ndash;&gt;-->

<!--                    <button-->
<!--                        class="btn pointer capitalize text-center flex items-center gap-4 main-bg  text-white  border-none py-2 px-9 rounded-3xl"-->
<!--                        @click="showLoginModal"-->
<!--                    >-->
<!--                        {{ $t('navbar.login') }}-->
<!--                    </button>-->
<!--                </template>-->
<!--                <template v-if="user && user.id">-->
<!--                    <button-->
<!--                        class="btn pointer capitalize text-center flex items-center gap-4 main-bg  text-white  border-none py-2 px-9 rounded-3xl"-->
<!--                        @click="logout"-->
<!--                    >-->
<!--                        Logout-->
<!--                    </button>-->
<!--                </template>-->
<!--            </div>-->
        </div>
    </nav>
    <div class="bg-blue-900 hidden lg:block">
        <div class="container mx-auto">
            <div class="flex items-center justify-evenly text-white">
                <router-link to="/" class="flex items-center no-underline gap-4 font-medium ml-4">
                    <hr class="text-white w-0.5 h-8 bg-white">
                    {{ $t("navbar_upd.homepage") }}
                    <hr class="text-white w-0.5 h-8 bg-white">
                </router-link>
                <div
                    v-for="nav in navbarEls"
                    class=""
                >
                    <div
                        class="flex items-center relative"
                        @mouseenter="showSubMenu(nav.id)"
                        @mouseleave="hideSubMenu"
                    >
                        <hr class="text-white w-0.5 h-8 bg-white">
                        <p
                            class="p-2 cursor-pointer"
                        >
                            {{nav.name}}
                        </p>
                        <hr class="text-white w-0.5 h-8 bg-white">
                        <div
                            v-if="showSubMenuId === nav.id"
                            class="z-50 absolute flex justify-between items-start bg-white text-sky-300 rounded-lg p-4 gap-2 items-start top-0 mt-10 transform -translate-x-1/2 left-1/2"
                        >
                            <router-link v-for="navItems in nav.elements" :to="`${navItems.link}`" class="cursor-pointer border-sky-300 border-2 p-4 rounded-xl mx-auto text-center flex flex-col items-center w-32 h-32 justify-between ">
                                <img class="w-auto" :src="require(`@/assets/png/${navItems.image}`)" alt="">
                                <p>{{navItems.title}}</p>
                            </router-link>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChangeLanguageDropdown from "./ui/ChangeLanguageDropdown"
import {mapGetters} from "vuex";
import {useI18n} from "vue-i18n/dist/vue-i18n";

export default {
    name: "Navbar",
    components: {ChangeLanguageDropdown},

    data() {
        return {
            burger: false,
            announcement: '',
            showSubMenuId: null,
            navbarElsData: [
                {
                    id: 2, nameKey:  "navbar_upd.products_and_prices", elements: [
                        {id: 1, titleKey: 'navbar_upd.products_notes.coverage_map', image: 'products-cov-map.png', link: '/products-prices/map'},
                        {id: 2, titleKey: 'navbar_upd.products_notes.prices', image: 'products-prices.png', link: '/products-prices/prices'},
                        {id: 3, titleKey: 'navbar_upd.products_notes.device_info', image: 'products-device.png', link: '/products-prices/device'},
                    ]
                },
                {
                    id: 3, nameKey: "navbar_upd.pickups_and_returns", elements: [
                        {id: 1, titleKey: 'navbar_upd.pickups_notes.how_to', image: 'how-to-pickup.png', link: '/pickups-returns/how-to'},
                        {id: 2, titleKey: 'navbar_upd.pickups_notes.airport', image: 'LAX-8.png', link: '/pickups-returns/airport'},
                        {id: 4, titleKey: 'navbar_upd.pickups_notes.more_locs', image: 'more-arrow.png', link: '/pickups-returns/more-locations'},
                    ]
                },
                {
                    id: 4, nameKey: "navbar_upd.how_to_use", elements: [
                        {id: 1, titleKey: 'navbar_upd.how_to_use_notes.tutorial', image: 'tutorial-how-to.png', link: '/how-to-use/tutorial'},
                        {id: 2, titleKey: 'navbar_upd.how_to_use_notes.travel', image: 'travel-how-to.png', link: '/how-to-use/travel'},
                        {id: 3, titleKey: 'navbar_upd.how_to_use_notes.business', image: 'business-how-to.png', link: '/how-to-use/business'},
                        {id: 4, titleKey: 'navbar_upd.how_to_use_notes.more_cases', image: 'more-arrow.png', link: '/how-to-use/more'}
                    ]
                },
                {
                    id: 5, nameKey: "navbar_upd.contact", elements: [
                        {id: 1, titleKey: 'navbar_upd.contact_notes.customer_sup', image: 'customer-contact.png', link: '/customersupport'},
                        {id: 2, titleKey: 'navbar_upd.contact_notes.for_business', image: 'business-contact.png', link: '/corporate'},
                    ]
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            user: 'getUser',
        }),
        getSubdomain() {
            let siteUrl = window.location.hostname
            let regex = /^([a-z0-9]+)./gi
            let res = regex.exec(siteUrl)
            return res[0]
        },
        navbarEls() {
            return this.navbarElsData.map(item => ({
                ...item,
                name: this.$t(item.nameKey),
                elements: item.elements.map(element => ({
                    ...element,
                    title: this.$t(element.titleKey)
                }))
            }));
        }
    },
    created() {
        this.$axios.get('/navbar-announcement/get-one')
            .then((res) => {
                this.announcement = res.data.name
            })
    },

    methods: {
        showSubMenu(id) {
            const nav = this.navbarEls.find((item) => item.id === id);
            if (nav && nav.elements) {
                this.showSubMenuId = id;
            }
        },
        hideSubMenu() {
            this.showSubMenuId = null;
        },
        toggleBurger() {
            this.burger = !this.burger
        },

        logout() {
            this.$store.dispatch("logout");

            this.$toast.info('You have logged out successfully', {
                position: "bottom-left",
            });

            this.$store.dispatch('setAuthResultType', 'logout');
            this.$router.push({name: 'AuthResult'});
        },

        showLoginModal() {
            this.$store.dispatch('setAuthResultType', 'home');
            this.$router.push({name: 'Login'});
        }
    }
}
</script>

<style lang="scss">
nav {
    background-color: #35b8f5;

    &:before {
        content: '';
        background-image: url("../assets/png/header-after.png");
        background-size: cover;
        left: 50%;
        bottom: 0;
        width: 100%;
        height: 85px;
        transform: translateX(-50%);
        position: absolute;
    }

    a {
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
}

</style>