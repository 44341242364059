<template>
    <div class="reviews-bg mt-40 px-4">
        <div class="container mx-auto">
            <div>
                <div class="mb-16">
                    <hr class=" w-12 blue-bg h-1 mb-4 mx-auto sm:mx-0"/>
                    <h2 class=" mb-8 text-4xl sm:text-5xl text-black text-center sm:text-left">FAQs</h2>
                </div>

            </div>
        </div>
        <Slider
            :slider-data="sliderItems"
        />
    </div>
</template>

<script>
import Slider from "../../components/ui/Slider";
import {useI18n} from "vue-i18n/dist/vue-i18n";

export default {
    name: "Reviews",
    components: {Slider},
    data() {
        return {
            sliderItems: [
                {
                    id: 1,
                    name: 'How Do I Return',
                    description: 'Each of our locations serves as a return location as well as shipping it back to us. All you have to do is give the device to an employee working at the location, and we\'ll handle the rest! You have 5 business days after your expected return date until you start getting charged for late fees* and an additional $5 fee will be charged if the device is returned at a different location than you picked it up.',
                    img: 'slide1-before.png'
                },
                {
                    id: 2,
                    name: 'Can I Rent Last Minute ?',
                    description: 'Of course! You are allowed to make the reservation whenever you\'d like.* *Some locations require you to book at least 3 days before, please read each location\'s directions thorougly.',
                    img: 'slide2-before.png'
                },
                {
                    id: 3,
                    name: 'Can I Change My Pick Up Location ?',
                    description: 'Yes! If something comes up and you need to change the pick-up location, contact us through email or call and we will make changes to your request free of charge!',
                    img: 'slide1-before.png'
                },
                {
                    id: 4,
                    name: 'Can Someone Else Pick Up For Me ?',
                    description: 'Yes, however that person will need to provide proof that they are someone that can pick up on your behalf. Person will need to provide the order number, as well as the email that was sent out to the original customer.',
                    img: 'slide2-before.png'
                },
                {
                    id: 5,
                    name: 'When Does My Hotspot Ship',
                    description: 'Unless otherwise specified, we will arrange for your hotspot to arrive 2 days before the start of your plan.',
                    img: 'slide2-before.png'
                },
                {
                    id: 6, name: 'How does insurance works?', description: 'Insurance service is $2 per day.', notes: [
                        '1. Please contact us as soon as possible if the device is lost or stolen.',
                        'Insured Customer\'s Copayment: $50',
                        'Non-Insured Customer: $300',
                        '2. Any calls/data made by a third party during the rental period shall be paid for by the customer.',
                        '*Any cost of broken, lost or stolen device able to be covered by your insurance.',
                    ], img: 'slide2-before.png'
                },
                {
                    id: 7, name: 'Can I use SMS (Short Mail) with WiFi router communication?', description: 'We\'re sorry, it is basically not available.', notes: [
                        'It depends on your contracted line. Please check with your contracted mobile phone company. ',
                        'SMS (Short Mail) sends SMS to your phone number, so communication with your mobile phone company is required.',
                        'For information on how to send and receive SMS overseas and fees, please contact your mobile phone company.',
                    ], img: 'slide2-before.png'
                },
                {
                    name: 'What is the approximate amount of data communication?', id: 8, description: 'Please refer to the following for the main data capacity guideline.', notes: [

                    ],
                    tables: [
                        {id: 1, plans: ['Web browsing', 'About 370 pages', 'About 750 pages', 'About 1,400 pages']},
                        {id: 2, plans: ['Map searching', 'About 40 minutes', 'About 1 hour 30 minutes', 'About 2 hour 50 minutes']},
                        {id: 3, plans: ['Watch Youtube', 'About 10 minutes', 'About 20 minutes', 'About 37 minutes']},
                        {id: 4, plans: ['Chatting on SNS', 'About 7,500', 'About 15,000', 'About 28,000']},
                        {id: 5, plans: ['Calling on SNS', 'About 5 hours', 'About 10 hours ', 'About 18 hours 40 minutes']},
                        {id: 6, plans: ['Send E-mail', 'About 120', 'About 250 ', 'About 470']},
                        {id: 7, plans: ['Calling on Skype', 'About 4 hours 20 minutes', 'About 8 hours 50 minutes', 'About 16 hours 30 minutes']},
                        {id: 8, plans: ['Video Calling on Skype', 'About 50 minutes', 'About 1 hour 40 minutes', 'About 3 hour ']},
                        {id: 9, plans: ['Viewing Instagram', 'About 30 minutes', 'About 1 hour ', 'About 1 hour 50 minutes']},
                    ], img: 'slide2-before.png'
                },
                {
                    id: 9, name: 'How many devices (or people) can be connected simultaneously with one Wi-Fi router?', description: 'Up to 5 devices (5 people) can be connected simultaneously.', notes: [
                        'If multiple connections are made with one device, the daily capacity will be divided between the number of people and the number of devices, so the available capacity will be less.',
                        'For multiple users, please select a super large capacity or unlimited plan.',
                        'If the communication capacity is exceeded, the speed may decrease or communication may not be possible.',
                    ], img: 'slide2-before.png'
                },
                {
                    id: 10, name: 'I forgot to return the device at the airport. How can I return it?', description: 'Please send it back by deliver service as soon as possible', notes: [
                        'The delivery fee will be pay by the customer.',
                        'Please use a delivery method that shows the delivery history, other than ordinary mail.',
                        'Please deliver all your divices such as the WiFi device, cables, adapters, and various optional accessories.',
                        'Please be careful not to forget to return the them. (Extension charge may apply)',
                        'You can also return at the Sky Go Office in Torrance.',
                        'Skygo WiFi ATTN:John Haruki\n' +
                        '370 Crenshaw Blvd E207\n' +
                        'Torrance CA 90503\n',
                    ], img: 'slide2-before.png'
                },
            ]
        }
    },
    setup() {
        const {t} = useI18n({
            useScope: 'global'
        })

        return {t}
    },
}
</script>

<style lang="scss">

.reviews-bg {
    h2 {
        font-weight: 700;
    }
}
</style>