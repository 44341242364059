<template>
    <div class="">
        <div class="mb-16 flex gap-6 items-end">
            <div class="flex gap-4 items-center">
                <h1 class="w-full text-3xl font-semibold text-black">
                    {{ $t('order.quantity') }}: {{ orderData.number_of_devices }}
                </h1>

                <div class="w-14 h-10 border-2 border-sky-300 rounded-full flex items-center justify-center">
                    <button
                        class="text-4xl flex mb-2 text-sky-300"
                        @click="changeQuantity('minus')"
                    >
                        -
                    </button>
                </div>

                <div class="w-14 h-10 border-2 border-sky-300 rounded-full flex items-center justify-center">
                    <button
                        @click="changeQuantity('plus')"
                        class="text-4xl flex mb-2 text-sky-300"
                    >
                        +
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../../components/ui/Button";
import _ from "lodash";
import {mapGetters} from "vuex";
import {useI18n} from "vue-i18n/dist/vue-i18n";

export default {
    name: "PurchaseRentAddProduct",

    components: {Button},
    data() {
        return {
            form: {
                number_of_devices: 1
            }
        }
    },
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },
    computed: {
        ...mapGetters({
            orderData: 'getOrderData',
            allOrderData: 'getAllOrderData',
        }),
    },

    methods: {
        changeQuantity(type) {
            let orderData = _.cloneDeep(this.allOrderData);
            orderData.forEach((order, i) => {
                this.$store.dispatch('setDestinationIndex', i)

                if (type === 'plus') {
                    order.number_of_devices += 1;
                } else if (type === 'minus' && orderData[0].number_of_devices > 1) {
                    order.number_of_devices -= 1;
                }
                const data = {
                    number_of_devices: order.number_of_devices,
                };
                this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });

            })
        },
    }
}
</script>

<style scoped>

</style>