<template>
    <div class="">
        <div class="w-full">
            <div class="w-full">
                <div class="  ">
                    <input-text
                        label="Gift Card Code"
                        placeholder="Gift Card Code"
                        v-model="couponCode"
                        @input="input"
                        size="coupon"
                    />
                </div>

                <template v-if="loading">
                    <div class="w-full flex justify-center items-center">
                        <spinner />
                    </div>
                </template>
                <template v-if="!loading">
                    <div
                        v-if="giftCardDiscount"
                        class="flex items-center gap-2 px-4 py-2 border-2 border-sky-300 rounded-xl mb-4"
                    >
                        <p class="">Your discount:</p>

                        <span
                            class="font-medium text-xl text-sky-300"
                            v-html="giftCardDiscount ? '$'+(giftCardDiscount): '$0'"
                        ></span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "@/components/ui/inputs/InputText";
import _ from "lodash";
import {mapGetters} from "vuex";
import Spinner from "@/components/ui/Spinner";
import {useI18n} from "vue-i18n/dist/vue-i18n";
import InputRadio from "@/components/ui/inputs/InputRadio";

export default {
    name: "PurchaseRentGiftCard",

    components: {InputRadio, Spinner, InputText},

    data() {
        return {
            couponCode: '',
            loading: false,
            popupShow: false,
            picked: true
        }
    },
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },
    computed: {
        ...mapGetters({
            giftCardDiscount: 'getGiftCardDiscount',
            orderData: 'getOrderData',
            allOrderData: 'getAllOrderData',
            selectedLang: 'getSelectedLanguage',
            insuranceStatus: 'getInsuranceStatus',
        }),
    },

    created() {

    },

    methods: {
        input: _.debounce(function (e) {
            this.loading = true;
            if (e && e.target.value) {
                this.$axios.get(`/gift-cards/${e.target.value}/getByCode`)
                    .then(res => {
                        this.$store.dispatch('setGiftCardDiscount', res.data[0].amount)
                        let coupon = res.data[0];
                        let orderData = _.cloneDeep(this.allOrderData);
                        orderData.forEach((order, i) => {
                            this.$store.dispatch('setDestinationIndex', i)

                            const data = {
                                gift_card_id: coupon.id,
                            }
                            this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                        })

                        this.loading = false;
                    })
                    .catch(() => {
                        this.$store.dispatch('setGiftCardDiscount', 0)
                        let orderData = _.cloneDeep(this.allOrderData);
                        orderData.forEach((order, i) => {
                            this.$store.dispatch('setDestinationIndex', i)

                            const data = {
                                gift_card_id: null
                            }
                            this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                        })
                        this.loading = false;
                    });
            } else {
                this.$store.dispatch('setGiftCardDiscount', 0)
                let orderData = _.cloneDeep(this.allOrderData);
                orderData.forEach((order, i) => {
                    this.$store.dispatch('setDestinationIndex', i)

                    const data = {
                        gift_card_id: null
                    }
                    this.$store.dispatch('setSameOrderData', { destinationIndex: i, data });
                })
                this.loading = false;
            }
        }, 300),
    }
}
</script>

<style scoped>

</style>