<template>
  <div class="container mx-auto px-4">
    <hr class="w-12 mx-auto main-bg h-1 mb-4" />
    <h2 class="text-center mb-8 text-5xl blue-text font-bold">Popular Countries</h2>
    <template v-if="loading.popularCountries">
      <div class="w-full flex justify-center">
        <spinner />
      </div>
    </template>
    <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 mb-8 px-2 md:px-0">

      <template v-if="!loading.popularCountries">
        <div
            v-for="item in popularCountries" :key="item.id"
            class="travel-country-card border-2 border-sky-300 rounded-lg py-4 text-center cursor-pointer hover:bg-sky-300 hover:text-white"
            :class="{'bg-sky-300 text-white': item.id === this.selectedCountry}"
            @click="selectPopularCountry(item)"
        >
          <img
              class="w-20 h-14 mx-auto rounded-lg"
              :src="require('../../assets/svg/' + item.img) "
              :alt="item.name"
          >
          <p class="mt-4 text-lg">
            {{ item.name }}
          </p>
        </div>
      </template>
    </div>
    <Button
        text="View More"
        color="none"
        size="lg"
        @click="$router.push('purchase-rent')"
        center="center"
    >
      <img src="@/assets/svg/arrow-right.svg" alt="">
    </Button>
  </div>
</template>

<script>
import Button from "../../components/ui/Button";
import CountryCard from "../../components/ui/CountryCard";
import _ from "lodash";
import {mapGetters} from "vuex";
import Spinner from "../../components/ui/Spinner";
export default {
  name: "Countries",
  components: {Spinner, CountryCard, Button},
  computed: {
    ...mapGetters({
      purchasingData: 'getPurchasingData',
    }),
  },
  data() {
    return {
      loading: {
        popularCountries: false,
        // continents: false,
        countries: false,
      },

      popularCountries: [],
      // continents: [],
      countries: [],

      // selectContinentKeywords: '',
      selectCountryKeywords: '',

      // selectedContinent: null,
      // continentOptions: [],

      selectedCountry: null,
      countryOptions: [],
    }
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      let purchasingData = _.cloneDeep(this.purchasingData);

      // this.selectedContinent = purchasingData.area.value ? purchasingData.area.value.value : null;
      this.selectedCountry = purchasingData.destination.value ? purchasingData.destination.value.value : null;

      this.loadPopularCountries();
      // this.loadContinents('loading');
      this.loadCountries('loading');
    },

    loadPopularCountries() {
      this.loading.popularCountries = true;

      this.$axios.get(`/countries/popular`, {
        params: {
          limit: 10
        }
      })
          .then(res => {
            this.popularCountries = res.data;
            const filteredCountries = this.popularCountries.filter(country => country !== null)
            this.popularCountries = filteredCountries
            this.loading.popularCountries = false;
          })
          .catch(e => {
            this.loading.popularCountries = false;
          });
    },

    // loadContinents(type = null) {
    //   if (type && type === 'clear') {
    //     this.selectContinentKeywords = '';
    //   }
    //
    //   if (type && type === 'loading') {
    //     this.loading.continents = true;
    //   }
    //
    //   this.$axios.get(`/continents/search`, {
    //     params: {
    //       limit: 10,
    //       keywords: this.selectContinentKeywords
    //     }
    //   })
    //       .then(res => {
    //         this.continents = res.data;
    //         this.parseContinentOptions(this.continents);
    //         this.loading.continents = false;
    //       })
    //       .catch(e => {
    //         this.loading.continents = false;
    //       });
    // },

    loadCountries(type = null) {
      if (type && type === 'clear') {
        this.selectContinentKeywords = '';
      }

      if (type && type === 'loading') {
        this.loading.countries = true;
      }

      let url = `/countries/search`

      if (this.selectedContinent) {
        url = `/continents/${this.selectedContinent}/countries`;
      }

      this.$axios.get(url, {
        params: {
          limit: 10,
          keywords: this.selectCountryKeywords
        }
      })
          .then(res => {
            this.countries = res.data;
            this.parseCountryOptions(this.countries);

            if (this.selectedCountry) {
              this.loadCountryById(this.selectedCountry);
            }

            this.loading.countries = false;
          })
          .catch(e => {
            this.loading.countries = false;
          });
    },

    // parseContinentOptions(data) {
    //   this.continentOptions = [];
    //
    //   data.forEach(item => {
    //     this.continentOptions.push({
    //       name: item.name,
    //       value: item.id
    //     });
    //   });
    // },

    parseCountryOptions(data) {
      this.countryOptions = [];

      data.forEach(item => {
        this.countryOptions.push({
          name: item.name,
          value: item.id
        });
      });
    },

    // selectContinent(data) {
    //   let purchasingData = _.cloneDeep(this.purchasingData);
    //   purchasingData.area.value = data;
    //   purchasingData.destination.value = null;
    //
    //   this.$store.dispatch('setPurchasingData', purchasingData);
    // },

    selectCountry(data) {
      let purchasingData = _.cloneDeep(this.purchasingData);
      purchasingData.destination.value = data;

      this.$store.dispatch('setPurchasingData', purchasingData);
      this.setData();
    },

    loadCountryById(id) {
      // this.loading = true;

      this.$axios.get(`/countries/${id}/show`)
          .then(res => {
            this.continentOptions.push({
              name: res.data.name,
              value: res.data.id
            });

            this.loading = false;
          })
          .catch(e => {
            this.loading = false;
          });
    },

    selectPopularCountry(country) {
      if (country && country.continent) {
        this.selectContinent({name: country.continent.name, value: country.continent.id});
      }

      if (country) {
        this.selectCountry({name: country.name, value: country.id});
      }
    }
  }
}
</script>

<style lang="scss">


</style>