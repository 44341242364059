<template>
    <app-layout>
        <hr class="w-12 main-bg h-1 mb-4 mx-auto mt-16">
        <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4">SkyGoWiFi Privacy Policy</h1>
        <div class="container mx-auto px-2">
            <h3 class="font-bold mb-4">Effective Date: <span class="font-normal">July 1st 2022</span></h3>
            <p>This Privacy Policy describes how Skygo Mobile Inc (&quot;SkyGoWiFi&quot;, &quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) collects,
                uses, and discloses your personal information when you use our website, skygowifi.com (the
                &quot;Site&quot;), and our Wi-Fi services (the &quot;Services&quot;).</p>
            <div v-for="item in privacy" :key="item.id">
                <h3 class="mt-4 font-bold">{{ item.title }}</h3>
                <template v-for="desc in item.descs">
                    <p class="my-4">{{ desc.head }}</p>
                    <ul v-if="desc.body" class="ml-4">
                        <li v-for="note in desc.body">
                            {{ note }}
                        </li>
                    </ul>
                </template>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "../layouts/AppLayout";

export default {
    name: "Privacy",
    components: {AppLayout},
    data() {
        return {
            privacy: [
                {
                    id: 1, title: 'Information We Collect', descs: [
                        {
                            id: 1,
                            head: 'We collect the following categories of personal information from you:',
                            body: [
                                '● Identifiers: such as your name, email address, phone number, IP address, device\n' +
                                'identifiers, and user name.',
                                '● Commercial Information: such as your purchase history and payment information.',
                                '● Internet or Electronic Activity Information: such as your browsing history, search\n' +
                                'history, and interactions with our website and services.',
                                '● Geolocation Information: such as your city, state, and country.'
                            ]
                        },
                        {
                            id: 2,
                            head: 'We collect this information from the following sources:',
                            body: [
                                '● Directly from you: such as when you create an account, make a purchase, or contact us',
                                '● Indirectly from you: such as through your use of our website and services.',
                                '● Third parties: such as our advertising partners and analytics providers.',
                            ]
                        },
                    ]
                },
                {
                    id: 2, title: 'How We Use Your Information', descs: [
                        {
                            id: 1,
                            head: 'We use your personal information for the following purposes:',
                            body: [
                                '● To provide you with the Services and fulfill your requests.',
                                '● To personalize your experience with our website and services.',
                                '● To send you marketing and promotional communications.',
                                '● To analyze the use of our website and services.',
                                '● To comply with legal requirements.',
                            ]
                        },
                    ]
                },
                {
                    id: 3, title: 'Sharing Your Information', descs: [
                        {
                            id: 1,
                            head: 'We may share your personal information with the following third parties:',
                            body: [
                                '● Service providers who help us operate our website and services.',
                                '● Advertising partners who help us provide you with targeted advertising.',
                                '● Analytics providers who help us understand how you use our website and services.',
                                '● Law enforcement agencies and other government officials, as required by law.',
                            ]
                        },
                        {
                            id: 2,
                            head: 'We will not sell your personal information to any third party.',
                        },
                    ]
                },
                {
                    id: 4, title: 'Your CCPA Rights', descs: [
                        {
                            id: 1,
                            head: 'If you are a California resident, you have the following rights under the California Consumer\n' +
                                'Privacy Act (CCPA):',
                            body: [
                                '● The right to know what personal information we collect, use, and disclose about you.',
                                '● The right to delete your personal information.',
                                '● The right to opt out of the sale of your personal information.',
                            ]
                        },
                        {
                            id: 2,
                            head: 'You can exercise your CCPA rights by contacting us at the following address:',
                            body: [
                                'Skygo Mobile, Inc.',
                                '370 Crenshaw Blvd E207',
                                'Torrance, CA 90277',
                                'You can also email us at info@skygowifi.com.'
                            ]
                        },
                    ]
                },
                {
                    id: 5, title: 'Data Retention', descs: [
                        {
                            id: 1,
                            head: 'We will retain your personal information for as long as necessary to provide you with the\n' +
                                'Services, comply with legal requirements, and resolve disputes.',

                        },
                    ]
                },
                {
                    id: 6, title: "Children's Privacy", descs: [
                        {
                            id: 1,
                            head: 'Our website and services are not directed to children under the age of 13. We do not knowingly\n' +
                                'collect personal information from children under the age of 13. If you are a parent or guardian\n' +
                                'and you believe that your child has provided us with personal information, please contact us at info@skygowifi.com',
                        },
                    ]
                },
                {
                    id: 7, title: 'Changes to this Privacy Policy', descs: [
                        {
                            id: 1,
                            head: 'We may update this Privacy Policy from time to time. We will notify you of any changes by\n' +
                                'posting the new Privacy Policy on our website. You are advised to review this Privacy Policy\n' +
                                'periodically for any changes.',
                        },
                    ]
                },
                {
                    id: 8, title: 'Contact Us', descs: [
                        {
                            id: 1,
                            head: 'If you have any questions about this Privacy Policy, please contact us at:',
                            body: [
                                'Skygo Mobile, Inc.',
                                'Attn: Privacy Department',
                                '370 Crenshaw Blvd E207',
                                'Torrance, CA 90277',
                                'Email: info@skygowifi.com'
                            ]
                        },
                    ]
                },
            ]
        }
    }
}
</script>

<style lang="scss">

</style>