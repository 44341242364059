<template>
    <div class="flex justify-between items-start">
        <p class="text-3xl font-semibold px-4 sm:px-0 mb-8 sm:mb-0 text-left text-black">
            {{ $t('order.choose_package') }}
        </p>
        <div class="font-medium hidden sm:block">
            <p class="mb-3">Need a custom plan specifically for your trip?</p>
            <div class="flex items-center flex-wrap">
                <p>Contact us using the</p>
                <div class="bg-[#39bef9] px-2 mx-2 py-1 rounded-full rounded-tr-none flex items-center gap-1">
                    <img class="help-icon" src="https://assetscdn-wchat.freshchat.com/static/assets/help_icon.407f0467c911236190038c9631c321f8.svg" alt="">
                    <p class="text-[#3A4B67]">Help</p>
                </div>
                button at the bottom right of the page.
            </div>
        </div>
    </div>
    <div v-if="countryPackages.length" class="mb-12">

        <div class="mb-4" v-for="(destination, destinationId) in allOrderData">
            <h2 class="px-4 sm:px-0 mb-2 text-xl">{{ destination.country_name }}</h2>
            <div class="items-center grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-12 md:px-0 px-4">
                <div
                    v-for="(item, index) in countryPackages[destinationId]"
                    class="relative font-bold"
                >
                    <button
                        v-if="item?.price && item?.name !== 'tuge'"
                        :key="item?.id"
                        :class="styleCountryPackageButton(item.id, destinationId)"
                        @click="selectCountryPackage(item, destinationId)"
                    >
                        {{ item?.name.toUpperCase() }} - ${{ item?.price }} Per day
                        <span @mouseover="isNoteActive[index] = true" @mouseleave="isNoteActive[index] = false">ⓘ</span>
                    </button>
                    <div :class="isNoteActive[index] ? 'block' : 'hidden'" class="bg-white absolute rounded-lg -top-8 -right-2 px-4 py-2 text-sm">
                        {{ item?.note }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="font-medium mb-5 block sm:hidden px-4">
        <p class="mb-3">Need a custom plan specifically for your trip?</p>
        <div class="flex items-center flex-wrap">
            <p>Contact us using the</p>
            <div class="bg-[#39bef9] px-2 mx-2 py-1 rounded-full rounded-tr-none flex items-center gap-1">
                <img class="help-icon" src="https://assetscdn-wchat.freshchat.com/static/assets/help_icon.407f0467c911236190038c9631c321f8.svg" alt="">
                <p class="text-[#3A4B67]">Help</p>
            </div>
            button at the bottom right of the page.
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import {useI18n} from "vue-i18n/dist/vue-i18n";

export default {
    name: "PurchaseRentPackages",

    data() {
        return {
            isNoteActive: []
        }
    },
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },
    computed: {
        ...mapGetters({
            orderData: 'getOrderData',
            countryPackages: 'getCountryPackages',
            allOrderData: 'getAllOrderData',
            destinationIndex: 'getDestinationIndex'
        }),
    },

    methods: {
        selectCountryPackage(item, destinationId) {
            this.$store.dispatch('setDestinationIndex', [destinationId])
            let orderData = _.cloneDeep(this.allOrderData[this.destinationIndex]);
            orderData.country_package_id = item.id;
            orderData.country_package_name = item.name;
            orderData.country_package_price = item.price;
            // orderData.offering_id = item.offering_id;
            orderData.offering_code = item.offering_code;
            this.$store.dispatch('clearOrderData', 'coupon');
            this.$store.dispatch('clearOrderData', 'insurance');
            // this.$store.dispatch('clearOrderData', 'other');
            this.$store.dispatch('setOrderData', orderData);
        },

        styleCountryPackageButton(id, destinationId) {
            let style = `bg-white border-2 sm:w-full border-sky-300 rounded-lg py-4 text-center cursor-pointer hover:bg-sky-300 hover:text-white w-full`;
            if (this.allOrderData[destinationId].country_package_id && id === this.allOrderData[destinationId].country_package_id) {
                style += ` bg-sky-300 text-white`;
            } else {
                style += ` bg-sky-white text-slate-800`;
            }

            return style;
        }
    }
}
</script>

<style scoped>

</style>