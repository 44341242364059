import {createStore} from 'vuex'
import {axiosInstance as axios} from "@/plugins/axios";

import AuthStore from '@/views/auth/store';
import PurchasingStore from "@/views/purchase/store";

export default createStore({
    state: {
        user: {},
    },

    mutations: {
        setUser(state, user) {
            state.user = user;
        },

        logout(state) {
            state.user = null;
            localStorage.removeItem("access-token");
        },
    },

    actions: {
        loadMe({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/me")
                    .then(({data}) => {
                        commit("setUser", data.user);

                        resolve(data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },

        logout(state) {
            if (state.user || localStorage.getItem("access-token")) {
                return new Promise((resolve, reject) => {
                    axios
                        .get("/logout")
                        .then(({data}) => {
                            state.commit("logout");
                            resolve(data);
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            }
        },

        setUser({commit}, payload) {
            commit('setUser', payload);
        }
    },

    getters: {
        getUser: (state) => {
            return state.user;
        },
    },

    modules: {
        AuthStore,
        PurchasingStore,
    }
})
