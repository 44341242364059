<template>
  <div class="blue-border p-6 rounded-xl">
    <div class="flex items-center gap-6 mb-2">
      <img src="@/assets/png/pro.png" alt="">
      <div>
        <h5 class="font-semibold">SkyGo Wifi Rental</h5>
        <p>Hotspot @ x 0</p>
        <div class="flex gap-2 items-center">
          <div class="blue-border rounded-lg px-1">
            <p class="">+</p>
          </div>
          <span>1</span>
          <div class="blue-border rounded-lg px-1">
            <p>-</p>
          </div>
        </div>
      </div>
      <p>$0.00</p>
    </div>
    <hr class="w-full blue-bg h-0.5 mb-2" />
    <ul>
      <li class="flex justify-between items-center mb-2">
        <p>Subtotal</p>
        <span>$0.00</span>
      </li>
      <li class="flex justify-between items-center mb-2">
        <p>Discount</p>
        <span>$0.00</span>
      </li>
      <li class="flex justify-between items-center mb-2">
        <p>Insurance</p>
        <span>$0.00</span>
      </li>
      <li class="flex justify-between items-center mb-2">
        <p>Shipping</p>
        <span>$0.00</span>
      </li>
      <li class="flex justify-between items-center mb-2">
        <p>Tax(0%)</p>
        <span>$0.00</span>
      </li>
    </ul>
    <hr class="w-full blue-bg h-0.5 mb-2" />
    <div class="flex justify-between items-center mb-8">
      <p class="font-semibold">Total</p>
      <span class="font-semibold">$0.00</span>
    </div>
    <p class="text-red-500">Return by: 2022-08-22</p>
    <span class="text-red-500">* Late fees begin accumulating after
            2022-08-22, $10 per day.</span>
  </div>
</template>

<script>
export default {
  name: "ProductInfo"
}
</script>

<style lang="scss">

</style>