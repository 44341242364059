<template>
    <app-layout>
        <hr class="w-12 main-bg h-1 mb-4 mx-auto mt-16">
        <h1 class="blue-text text-5xl font-bold text-center mb-12 px-4">{{ $t("pickup_and_returns.title") }}</h1>
        <div class="flex items-start container mx-auto justify-evenly flex-wrap lg:flex-nowrap gap-16 px-2">
            <img src="@/assets/png/how-to-pickpup-return.png" alt="">
            <div>
                <div
                    v-for="notes in howToNotes"
                >
                    <h3 class="text-xl font-bold mb-2">{{ notes.note }}</h3>
                    <p
                        v-for="subs in notes.subtitles"
                        class="mb-2"
                    >
                        {{ subs }}
                    </p>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
export default {
    name: "HowTo",
    components: {AppLayout},
    data() {
        return {
            howToNotes: [
                {
                    id: 1,
                    note: this.$t("pickup_and_returns.steps.first.title"),
                    subtitles: [
                        this.$t("pickup_and_returns.steps.first.notes.note1"),
                        this.$t("pickup_and_returns.steps.first.notes.note2"),
                        this.$t("pickup_and_returns.steps.first.notes.note3")
                    ]
                },
                {
                    id: 2,
                    note: this.$t("pickup_and_returns.steps.second.title"),
                    subtitles: [
                        this.$t("pickup_and_returns.steps.second.notes.note1"),
                        this.$t("pickup_and_returns.steps.second.notes.note2"),
                        this.$t("pickup_and_returns.steps.second.notes.note3")
                    ]
                },
                {
                    id: 3,
                    note: this.$t("pickup_and_returns.steps.third.title"),
                    subtitles: [
                        this.$t("pickup_and_returns.steps.third.notes.note1")
                    ]
                }
            ]
        };
    }
}
</script>

<style scoped>

</style>