<template>
    <div class="slider-item relative p-6 text-left bg-white shadow-md rounded-lg">
        <h4 class="mb-4">{{ itemData.name }}</h4>
        <p>
            <template v-if="itemData.description.split('\n').length > 5">
                <span v-if="showFullDescription">
                    {{ itemData.description }}
                </span>
                <span v-else>
                {{ itemData.description.split('\n').slice(0, 5).join('\n') }}
                    <a href="#" @click.prevent="showFullDescription = true">...</a>
                </span>
            </template>
            <template v-else>
                {{ itemData.description }}
            </template>
        </p>
        <div>
            <template v-if="itemData.notes?.length > 5">
                <div v-if="showFullNotes">
                    <p v-for="note in itemData.notes">{{ note }}</p>
                </div>
                <div v-else>
                    <p v-for="note in itemData.notes.slice(0, 5)">{{ note }}</p>
                    <a href="#" @click.prevent="showFullNotes = true">...</a>
                </div>
            </template>
            <template v-else>
                <p v-for="note in itemData.notes">{{ note }}</p>
            </template>
        </div>
        <div :class="itemData?.tables ? 'overflow-scroll h-72 sm:h-56 w-[300px] md:w-72 xl:w-auto' : ''">
            <table class="border-collapse border border-slate-500 overflow-x-auto">
                <thead>
                <tr v-if="itemData?.tables">
                    <th class="p-2">Coverage Application</th>
                    <th class="p-2">Normal Plan (300MB/day)</th>
                    <th class="p-2">Large capacity plan (600MB/day)</th>
                    <th class="p-2">Super large capacity plan (1.1GB/day)</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="plans in itemData?.tables">
                    <td class="border border-slate-500 p-2" v-for="plan in plans.plans">{{ plan }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <img class="absolute top-0 right-0" :src="require('../../assets/png/' + itemData.img)" alt="">
    </div>
</template>

<script>
export default {
    name: "SliderItems",
    props: {
        itemData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            showFullDescription: false,
            showFullNotes: false,
        }
    }
}
</script>

<style lang="scss">
.slider-item {
    width: 570px;
    display: inline-block;

    h4 {
        font-weight: 700;
        font-size: 24px;
        color: #000;
    }

    p {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

</style>