<template>
  <div class="login relative">
    <div class="pb-20 pt-5 flex justify-center">
      <router-link to="/" class="logo">
        <img class="mx-auto" src="@/assets/png/skygo-logo.png" alt="">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavLogin"
}
</script>

<style lang="scss">
.login {
  background-color: #35b8f5;

&:before {
   content: '';
   background-image: url("../assets/png/header-after.png");
   background-size: cover;
   left: 50%;
   bottom: 0;
   width: 100%;
   height: 85px;
   transform: translateX(-50%);
   position: absolute;
 }
}
</style>