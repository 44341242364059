<template>
    <app-layout>
        <div class="w-full bg-white">
            <div class="py-32 px-4 flex justify-center gap-8 xl:gap-0 items-center container mx-auto max-w-5xl flex-wrap">
                <div>
                    <h1 class="text-5xl font-semibold mb-10 text-center text-sky-300">
                        Thank you for applying to become a Skygo.
                    </h1>
                    <div class="mb-10 text-center">
                        <h2 class="text-2xl font-semibold text-orange-500">
                            We have received your form and will be in touch soon with the next steps. Excited to potentially work together!
                        </h2>
                    </div>
                    <button
                        class="mb-10 btn pointer capitalize text-center text-xl font-semibold flex items-center gap-4 main-bg m-auto text-white border-none py-4 px-10 rounded-3xl"
                        @click="redirect"
                    >
                        Go to home page
                    </button>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
export default {
    name: "ContactResultPage",
    components: {AppLayout},
    methods: {
        redirect() {
            this.$router.push({name: 'Home'})
        }
    }
}
</script>

<style scoped>

</style>

