<template>
    <app-layout>
        <div class="wrapper container mx-auto">

            <div class="login-container mx-auto border rounded-2xl p-4 sm:p-8 mt-32">

                <h2 class="text-3xl mb-6">{{ $t('auth.login_to_rent') }}</h2>

                <input-text
                    placeholder="Email address"
                    type="email"
                    :error-message="errors && errors.email ? errors.email[0] : errors ? errors[0] : ''"
                    v-model="form.email"

                />

                <input-text
                    type="password"
                    :error-message="errors && errors.password ? errors.password[0] : errors ? errors[0] : ''"
                    v-model="form.password"
                    @keyup.enter="submit"
                    placeholder="Password"
                />

                <div class="flex justify-between items-center flex-wrap gap-2 sm:gap-0">
                    <Button
                        :text="$t('auth.login')"
                        :loading="loading"
                        @click="submit"
                    />

                    <div class="flex gap-1 flex-wrap sm:flex-nowrap">
                        <p>{{ $t('auth.no_acc') }}</p>
                        <router-link to="/register">{{ $t('auth.register') }}</router-link>
                    </div>
                </div>
                <router-link to="/reset" class="reset-text block text-right font-semibold text-sm ">{{ $t('auth.reset') }}</router-link>
            </div>

        </div>
    </app-layout>
</template>

<script>
import EmailInput from "../../components/ui/EmailInput";
import PasswordInput from "../../components/ui/PasswordInput";
import NavLogin from "../../components/NavLogin";
import Button from "../../components/ui/Button";
import Footer from "../../components/Footer";
import Contacts from "../home-pages/Contacts";
import AppLayout from "../../layouts/AppLayout";
import InputText from "@/components/ui/inputs/InputText";
import {mapGetters} from "vuex";
import {useI18n} from "vue-i18n/dist/vue-i18n";

export default {
    name: "Login",

    components: {InputText, AppLayout, Contacts, Footer, Button, NavLogin, PasswordInput, EmailInput},

    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            errors: null,
            loading: false,
        }
    },
    setup() {
        const { t } = useI18n({
            useScope: 'global'
        })

        return { t }
    },
    computed: {
        ...mapGetters({
            authResultType: 'getAuthResultType',
        }),
    },

    methods: {
        submit() {
            this.loading = true;

            this.$axios.post(`/login`, this.form)
                .then(res => {
                    if (res.data.token) {
                        this.$store.dispatch('setUser', res.data.user);
                        localStorage.setItem("access-token", res.data.token);
                        this.$store.dispatch("loadMe");

                        this.$router.push({name: 'AuthResult'});
                    }
                    this.loading = false;
                })
                .catch(e => {
                    if (e.status === 422) {
                        this.errors = e.data.errors;
                        console.log(e.data.errors)
                    }
                    if (e.status === 404) {
                        this.errors = ['Wrong email or password'];
                    }
                    this.loading = false;
                });
        }
    }
}
</script>

<style lang="scss">
.wrapper {
    @media screen and (max-width: 576px) {
        padding: 0 1.5rem;
    }

    .login-container {
        border-color: #f1792d;
        max-width: 560px;
        width: 100%;

        h2 {
            font-weight: bold;
            color: #f1792d;
            text-align: center;
        }

        button {
            border-radius: 4px;
        }

        a {
            font-size: 18px;
            font-weight: bolder;
            color: #f1792d;
        }
      .reset-text {
        color: #f1792d;
      }
    }
}
</style>