<template>
    <app-layout>
        <div class="container mx-auto px-2">
            <div class="my-16">
                <hr class="w-12 mx-auto main-bg h-1 mb-4" />
                <h1 class="blue-text text-5xl font-bold text-center px-4">Become Skygo Dealer</h1>
            </div>
            <h2 class="text-center text-3xl mb-16">Thank you for your interest in becoming a Skygo Wifi Dealer!</h2>
            <div class="max-w-5xl mx-auto">
                <p class="text-center text-xl">We appreciate your enthusiasm.
                    <br>
                    This is a great opportunity to expand your business and earn more. We look forward to discussing the potential partnership with you and providing you
                    with more details on how you can maximize your earn potential.
                    <br>
                    To proceed further and receive more information, please provide us with your contact details, including your phone number and email address.
                    Our team will reach out to you promptly.
                </p>
                <div class="mt-6">
                    <div class="flex xl:gap-6 justify-between ">
                        <input-text
                            class="xl:w-1/2"
                            label="Your name"
                            placeholder="Name"
                            v-model="form.name"
                        />
                        <input-text
                            class="xl:w-1/2"
                            label="Your email"
                            placeholder="Email"
                            v-model="form.email"
                        />
                    </div>
                    <label class="block mb-2 text-sm" for="">Message</label>
                    <textarea v-model="form.message" placeholder="Message" class="h-56 border-sky-300 outline-blue-300 block w-full rounded-xl border focus:outline-offset-3 focus:outline-4 pl-4 text-base p-2" />
                    <button
                        @click="sendMessage"
                        class="px-12 mt-6 rounded-xl hover:bg-sky-300 hover:text-white transition border text-base p-2 bg-white border-sky-300 text-black outline-blue-300"
                    >
                        {{ loading ? 'Sending...' : 'Send' }}
                    </button>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import InputText from "@/components/ui/inputs/InputText";
import Button from "@/components/ui/Button";
export default {
    name: "Contact",
    components: {Button, InputText, AppLayout},
    data() {
        return {
            form: {
                name: '',
                message: '',
                email: ''
            },
            loading: false
        }
    },
    methods: {
        sendMessage() {
            // Regular expression for email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (this.form.name && this.form.message && this.form.email) {
                if (!emailRegex.test(this.form.email)) {
                    this.$toast.warning('Please enter a valid email address', {
                        position: "bottom-left",
                    });
                    return;
                }

                this.loading = true;
                this.$axios.post(`/contacts/contact-us`, this.form)
                    .then(res => {
                        console.log(res);
                        this.form.name = '';
                        this.form.message = '';
                        this.form.email = '';
                        this.loading = false;
                        this.$router.push({name: 'ContactResultPage'})
                    })
                    .catch(e => {
                        if (e.status === 422) {
                            this.errors = e.data.errors;
                        }
                        this.loading = false;
                    });
            } else {
                this.$toast.warning('Please fill all fields', {
                    position: "bottom-left",
                });
            }
        }
    }
}
</script>

<style scoped>

</style>