import axiosApi from "axios";
import _ from "lodash";

const axios = axiosApi.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "X-Custom-Header": "XMLHttpRequest",
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
  },
  // withCredentials: true,
});

axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(function (config) {
  let token = localStorage.getItem("access-token");

  if (token) {
    config.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    if (response.headers && _.has(response.headers, "authorization")) {
      localStorage.setItem("access-token", response.headers.authorization);
    }

    return response;
  },
  function (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {

    }

    return Promise.reject(error.response);
  }
);

export const axiosInstance = axios;

export const axiosPlugin = {
  install: (app) => {
    app.config.globalProperties.$axios = axios;
  }
}
